module.exports = {
    special_column: 'ᠲᠤᠰᠬᠠᠢ ᠪᠤᠯᠤᠩ', // 专栏
    followers: 'ᠠᠩᠬᠠᠷᠤᠭᠴᠢ', // 关注者
    add_course: ' ᠰᠢᠨ᠎ᠡ ᠬᠢᠴᠢᠶᠡᠯ ᠪᠠᠶᠢᠭᠤᠯᠬᠤ', // 新建单课
    add_cloudclass: ' ᠰᠢᠨ᠎ᠡ ᠬᠢᠴᠢᠶᠡᠯᠯᠡᠭᠡ ᠪᠠᠶᠢᠭᠤᠯᠬᠤ', // 新建课程
    chapter_name: 'ᠪᠦᠯᠦᠭ ᠬᠡᠰᠡᠭ ᠦᠨ ᠨᠡᠷᠡᠶᠢᠳᠦᠯ', // 章节名称
    all: 'ᠪᠦᠬᠦ', // 全部
    unpublished: 'ᠨᠡᠶᠢᠲᠡᠯᠡᠭᠰᠡᠨ ᠥᠬᠡᠶ', // 未发布
    published: 'ᠨᠡᠶᠢᠲᠡᠯᠡᠭᠰᠡᠨ', // 已发布
    release: "ᠨᠡᠶᠢᠲᠡᠯᠡᠬᠦ",//发布
    status_failed: 'ᠥᠩᠭᠡᠷᠡᠭᠰᠡᠨ ᠥᠬᠡᠶ', // 未通过
    lesson_group: 'ᠬᠢᠴᠢᠶᠡᠯ ᠪᠡᠯᠡᠳᠬᠡᠬᠦ ᠳᠤᠭᠤᠢᠯᠠᠡᠭ᠍', // 备课组
    subject_group: 'ᠬᠢᠴᠢᠶᠡᠯ ᠳᠦᠷᠦᠯ ᠦᠨ ᠳᠤᠭᠤᠢᠯᠠᠩ', // 学科组
    personal: 'ᠬᠤᠪᠢ ᠬᠦᠮᠦᠨ', // 个人
    organization: 'ᠰᠠᠯᠠᠭ᠎ᠠ ᠮᠦᠴᠢᠷ', // 组织
    course_name: 'ᠬᠢᠴᠢᠶᠡᠯ ᠦᠨ ᠨᠡᠷᠡᠶᠢᠳᠦᠯ', // 总结成功
    course_content: 'ᠰᠤᠷᠬᠤᠶ ᠶᠢᠨ ᠠᠭᠤᠯᠠᠨ᠎ᠠ', // 学习内容
    content: 'ᠠᠭᠤᠯᠠᠨ᠎ᠠ', // 内容
    course_collect: 'ᠬᠤᠷᠢᠶᠡᠠ ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ', // 收藏
    course_upload: 'ᠠᠴᠢᠶᠠᠯᠠᠬᠤ', //  上传
    click_and_drop: 'ᠲᠠᠷᠤᠬᠤ ᠪᠤᠶᠤ ᠹᠠᠢᠯ ᠢᠶᠡᠨ ᠲᠠᠳᠡᠵᠤ ᠡᠨᠳᠡ ᠪᠠᠢᠷᠠᠰᠢᠭᠤᠯᠵᠦ ᠠᠴᠢᠶᠠᠯᠠᠭᠠᠷᠠᠢ', // 点击或者拖拽到这里
    select_file_library: 'ᠮᠠᠲ᠋ᠧᠷᠢᠶᠠᠯ ᠤᠨ ᠰᠠᠩ ᠡᠴᠡᠰᠤᠩᠭᠤᠬᠤ', // 选择文件库
    the_delete_data: 'ᠲᠤᠰ ᠵᠣᠷᠪᠣᠰ ᠢ ᠬᠠᠰᠤᠬᠤ ᠦᠦ ？', // 确定要删除这条数据

    delete_data: 'ᠲᠣᠭ᠎ᠠ ᠪᠠᠷᠢᠮᠲᠠ ᠶᠢ ᠬᠠᠰᠤᠬᠤ ᠭᠡᠵᠦ ᠦᠦ ？', // 删除数据   
    determine: 'ᠳᠡᠭᠡ', // 确定
    cancel: 'ᠦᠭᠡᠢ', // 取消
    ok: 'ᠪᠣᠯᠤᠨ᠎ᠠ', // 确定
    no: 'ᠬᠠᠭᠠᠬᠤ', // 取消
    title: 'ᠭᠠᠷᠴᠠᠭ', // 标题
    picture: "ᠵᠢᠷᠤᠭ", //图片
    file_video: "ᠳᠦᠷᠰᠦ  ᠪᠢᠴᠢᠯᠭᠡ", //视频
    task: "ᠰᠡᠳᠦᠪ",//题
    zh_web: 'ᠬᠢᠲᠠᠳ ᠰᠦᠯᠵᠢᠶ᠎ᠡ',
    add_task: "ᠰᠡᠳᠦᠪ ᠨᠡᠮᠡᠬᠦ",//添加题
    select_task: "ᠰᠡᠳᠦᠪ  ᠢᠶᠡᠨ ᠰᠣᠩᠭᠣᠭᠠᠷᠠᠢ",//选择题库
    file_audio: "ᠳᠠᠭᠤ ᠪᠢᠴᠢᠯᠭᠡ", //音频
    introduction: 'ᠲᠠᠨᠢᠯᠴᠠᠭᠤᠯᠭ᠎ᠠ', // 简介
    keep_learning: "ᠦᠷᠭᠦᠯᠵᠢᠯᠡᠨ ᠰᠤᠷᠤᠯᠴᠠᠬᠤ",//继续学习
    extend: 'ᠥᠷᠭᠡᠳᠬᠡᠬᠦ ᠰᠣᠩᠭᠣᠯᠲᠠ ', // 扩展
    number_of_student: " ᠬᠦᠮᠦᠨ ᠰᠤᠷᠤᠯᠴᠠᠪᠠ",//学习人数
    brief_introduction: 'ᠲᠣᠪᠴᠢ ᠲᠠᠨᠢᠯᠴᠠᠭᠤᠯᠭ᠎ᠠ', // 简要介绍
    detailed_introduction: 'ᠨᠠᠷᠢᠨ ᠲᠠᠨᠢᠯᠴᠠᠭᠤᠯᠭ᠎ᠠ', // 详细介绍
    release_status: 'ᠨᠡᠶᠢᠲᠡᠯᠡᠭᠰᠡᠨ ᠪᠠᠢᠳᠠᠯ', // 发布状态
    cover_map: 'ᠨᠢᠭᠤᠷ ᠤᠨ ᠵᠢᠷᠤᠭ', // 封面图
    select_picture: 'ᠵᠢᠷᠤᠭ ᠰᠣᠩᠭᠣᠬᠤ', // 选择图
    keep_looking: 'ᠦᠷᠬᠦᠯᠵᠢᠯᠡᠨ ᠦᠵᠡᠬᠦ ᠭᠡᠪᠡᠯ ︽ ᠰᠤᠷᠤᠯᠴᠠᠬᠤ ︾ ᠳᠠᠷᠤᠪᠴᠢ ᠶᠢ ᠳᠠᠷᠤᠵᠤ ᠂ ᠰᠤᠷᠤᠯᠴᠠᠬᠤ ᠨᠢᠭᠤᠷ ᠲᠤ ᠵᠠᠯᠭᠠᠨ ᠦᠵᠡᠭᠡᠷᠡᠢ ︕',//继续查看请点击学习按钮，进行学习
    sort: 'ᠵᠢᠭᠰᠠᠭᠠᠯᠲᠠ', // 排序
    learning_class: 'ᠰᠤᠷᠤᠯᠴᠠᠭᠰᠠᠨ',//学习的
    money: 'ᠵᠣᠭᠣᠰ', // 金额
    "drafts": "ᠨᠤᠤᠷᠠᠭ",//草稿箱
    reference_material: "ᠯᠠᠪᠯᠠᠯᠳᠠ ᠶᠢᠨ ᠮᠠᠲ᠋ᠸᠷᠢᠶᠠᠯ",//参考资料
    single_square: "ᠬᠢᠴᠢᠶᠡᠯ ᠦᠨ ᠲᠠᠯᠠᠪᠠᠢ",//单课广场
    cloud_square: "ᠬᠢᠴᠢᠶᠡᠯᠯᠡᠭᠡ  ᠶᠢᠨ ᠲᠠᠯᠠᠪᠠᠢ",//课程广场
    cloud_name: 'ᠬᠢᠴᠢᠶᠡᠯᠯᠡᠭᠡ  ᠶᠢᠨ ᠨᠡᠷᠡᠶᠢᠳᠦᠯ',//课程名称
    sort_success: "ᠠᠮᠵᠢᠯᠲᠠ  ᠲᠠᠢ ᠵᠢᠭᠰᠠᠭᠠᠭᠰᠠᠨ",//排序成功
    integral: 'ᠴᠣᠮᠬᠠᠳᠬᠠᠯ', // 积分
    learning: "ᠰᠤᠷᠤᠯᠴᠠᠬᠤ",//学习
    add_succes: 'ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠨᠡᠮᠡᠵᠠᠢ', //添加成功
    learning_time: 'ᠰᠤᠷᠤᠯᠴᠠᠬᠤ ᠴᠠᠭ  ᠤᠨ ᠰᠠᠨᠠᠭᠤᠯᠤᠮᠵᠢ ', // 建议学习时长
    issue_number: 'ᠨᠡᠶᠢᠲᠡᠯᠡᠬᠦ ᠳ᠋ᠤᠭᠠᠷᠯᠠᠯ', // 发布编号
    access_password: 'ᠠᠶᠢᠯᠴᠢᠯᠠᠭᠰᠠᠨ ᠨᠢᠭᠲᠠᠴᠠ', // 访问密码
    can_download: 'ᠪᠠᠭᠤᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠡᠰᠡᠬᠦ', // 是否可下载
    there_charge: 'ᠲᠥᠯᠥᠪᠥᠷᠢ ᠲᠠᠢ ᠡᠰᠡᠬᠦ ', // 是否收费
    previewable_ratio: 'ᠤᠷᠢᠳᠴᠢᠯᠠᠨ ᠥᠵᠡᠬᠦ ᠨᠤᠷᠮ᠎ᠠ', // 可预览比率
    please_enter_previewable: '0᠎ ｜100 ᠶᠢᠨ ᠤᠷᠢᠳᠴᠢᠯᠠᠨ ᠦᠵᠡᠬᠦ ᠨᠤᠷᠮ᠎ᠠ ᠶᠢ ᠪᠢᠴᠢᠭᠡᠷᠡᠢ (0 ᠨᠢ ᠤᠷᠢᠳᠴᠢᠯᠠᠨ ᠦᠵᠡᠵᠦ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ ᠂100ᠨᠢ ᠪᠥᠬᠥᠨ ᠢ ᠤᠷᠢᠳᠴᠢᠯᠠᠨ ᠦᠵᠡᠵᠦ ᠪᠣᠯᠤᠨ᠎ᠠ )', // 请输入可预览比率 0-100(0不可预览、100是完全可以预览)
    preservation: 'ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ', // 保存
    add_file: 'ᠹᠠᠢᠯ ᠨᠡᠮᠡᠬᠦ', // 添加文件
    not_downloadable: 'ᠪᠠᠭᠤᠯᠭᠠᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ ', // 不可下载
    downloadable: 'ᠪᠠᠭᠤᠯᠭᠠᠵᠤ ᠪᠣᠯᠤᠨ᠎ᠠ', // 可下载
    charge: 'ᠲᠥᠯᠥᠪᠥᠷᠢ ᠲᠠᠢ', // 收费
    free: 'ᠲᠥᠯᠥᠪᠥᠷᠢ ᠦᠭᠡᠢ', // 免费
    hyperlinks: "ᠬᠠᠶ᠋ᠢᠭ",//超链接
    file_change: 'ᠹᠠᠢᠯ ᠰᠤᠯᠢᠬᠤ', // 更换文件
    file_upload: 'ᠹᠠᠢᠯ ᠠᠴᠢᠶᠠᠯᠠᠬᠤ', // 上传文件
    serial_status: 'ᠦᠷᠭᠦᠯᠵᠢᠯᠡᠭᠰᠡᠨ ᠪᠠᠢᠳᠠᠯ', // 连载状态
    serial: 'ᠦᠷᠭᠦᠯᠵᠢᠯᠡᠬᠦ', // 连载
    finish: 'ᠳᠠᠭᠤᠰᠪᠠ', // 结束
    opening_time: 'ᠬᠢᠴᠢᠶᠡᠯ ᠡᠬᠢᠯᠡᠬᠦ ᠴᠠᠭ', // 开课时间
    course_message: 'ᠬᠢᠴᠢᠶᠡᠯ ᠦᠨ ᠬᠠᠮᠢᠶᠠᠷᠤᠯᠲᠠ', // 课程管理
    data_analysis: 'ᠳ᠋ᠠᠶᠢᠲ᠋ᠠ ᠶᠢᠨ ᠵᠠᠳᠠᠯᠤᠯᠲᠠ', // 数据分析
    student_massage: 'ᠰᠤᠷᠤᠭᠴᠢ ᠶᠢᠨ ᠬᠠᠮᠢᠶᠠᠷᠤᠯᠲᠠ', // 学生管理
    new_chapter: 'ᠰᠢᠨ᠎ᠡ ᠪᠦᠯᠦᠭ', // 新单元
    add_attachment: "ᠯᠠᠪᠯᠠᠬᠤ ᠬᠣᠯᠪᠣᠭᠠᠰᠤ", //添加参考资料
    single_class_audit: 'ᠬᠢᠴᠢᠶᠡᠯ ᠦᠨ ᠬᠢᠨᠠᠯᠲᠠ ', // 单课审核
    cloud_classroom_audit: 'ᠬᠢᠴᠢᠶᠡᠯᠯᠡᠭᠡ ᠶᠢᠨ ᠬᠢᠨᠠᠯᠲᠠ ',  // 云课堂审核
};
