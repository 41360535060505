import gql from 'graphql-tag';

/**
 * 查询学段列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const periodList = gql`
	query ($page: Int!, $limit: Int!){
		periods (page: $page, limit: $limit){
            code
            msg
            total
            data {
                id
                name {
                    en
                    mn
                    zh
                }
                number
                slug
                mark
            }
		}
	}
`;
const schoolPeriods = gql`
    query ($page: Int!, $limit: Int!){
        schoolPeriods (page: $page, limit: $limit){
            code
            msg
            total
            data {
                id
                name {
                    en
                    mn
                    zh
                }
                number
                slug
                mark
            }
        }
    }
`
export {
    periodList,
    schoolPeriods
}