import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';import { UserMenuList } from 'src/app/model/home';
import { formatterLanguage } from 'src/app/utils';
8

@Injectable({
	providedIn: 'root'
})
export class MenuService {
	//	面包屑
	private breadCrumbsList:string[] =['ᠲᠡᠷᠢᠭᠦᠨ ᠨᠢᠭᠤᠷ'];
	valueUpload:Subject<Array<string>> = new Subject<Array<string>>();
	constructor(

	) { }
	makeTree(data:any,pid:number | null, level:number){
		let tree =[]
		let rootData = data.filter((dataItem)=>{
			let status = false;
			if(typeof(dataItem.parent_id) === 'undefined'){
				status = pid = null
			}else{
				status = dataItem.parent_id === pid;
			}
			return status
		})
		let that = this;
		rootData.forEach(function(item){
			//将当前元素赋值给临时变量
			let current_item = item;
			current_item.level = level;
			//找单曲item的子元素，即找出data[*].parent_id == item.id的数据
			let item_id = item.id;
			let child_data = data.filter(function(dataItem){
				return dataItem.parent_id === item_id
			})
			//如果有子元素对子元素进行递归处理；如果当前元素有子元素，将子元素赋值给上面的临时变量current_item 的children 上
			if(child_data.length > 0){
				current_item.children = that.makeTree(child_data,current_item.id,level+1);
			}
			//将当前查询结果赋值给返回数组
			tree.push(current_item)
		});
		return tree ;

	}
	//设置面包屑的值
	setBreadCrumbs(val:string[]){
		console.log(val);
		
		this.breadCrumbsList = val;
		this.valueUpload.next(this.breadCrumbsList);
		localStorage.setItem("BreadCrumbs",JSON.stringify(val))
	}
	//获取面包屑的值
	getBreadCrumbs():string[]{
		return localStorage.getItem("BreadCrumbs")?JSON.parse(localStorage.getItem("BreadCrumbs")):this.breadCrumbsList
	}
	formatterLocalMenus(data:UserMenuList[],parentId:number,status:string){
		let value:any = [];
		for (let i = 0; i < data.length; i++) {
			if (data[i].id === parentId) {
				if(status === 'module'){
					value.push(formatterLanguage(data[i].title));

				}else if(status === 'menu'){
					value.push(data[i]);
				}
				if(data[i].parent_id){
					this.formatterLocalMenus(data,data[i].parent_id,status);
				}
				break;

			}
			
		}
		return value;
	}
	setBread(val: UserMenuList, data: UserMenuList[], status: string, isFormatter?: boolean) {
        let list: string[] = [];
        const title: any = isFormatter ? val.title : formatterLanguage(val.title);
        if (val && val.parent_id) {
            const resultList: UserMenuList[] = this.formatterLocalMenus(data, val.parent_id, status);
            let resultTitle: string[] = [];
            if (typeof (resultList) === 'string') {
                resultTitle.push(resultList);
            } else {
                resultList.map((item) => {
                    resultTitle.push(formatterLanguage(item.title));
                })
            }
            list = list.concat(resultTitle);
        }
        list.push(title);
        this.setBreadCrumbs(list)
    }
}
