module.exports = {
    "teacher_user": 'ᠪᠠᠭᠰᠢ', //教师用户
    "username": "ᠤᠪᠤᠭ ᠨᠡᠷ᠎ᠡ",//姓名
    "head_portrait": "ᠲᠣᠯᠣᠭᠠᠶ ᠶᠢᠨ ᠵᠢᠷᠤᠭ",//头像
    "security_upload_photos": 'ᠭᠡᠷᠡᠯ ᠵᠢᠷᠤᠭ ᠢ ᠠᠴᠢᠶᠠᠯᠠᠬᠤ',//上传照片
    "sex": 'ᠴᠢᠨᠠᠷ ᠤᠨ ᠢᠯᠭᠠᠯ', //性别
    "select_birthday": 'ᠲᠦᠷᠦᠭᠰᠡᠨ ᠡᠳᠦᠷ ᠰᠣᠩᠭᠣᠬᠤ', //选择出生日期
    "contact_number": "ᠬᠠᠷᠢᠯᠴᠠᠬᠤ ᠤᠲᠠᠰᠤ",//联系电话
    "idcard": 'ᠪᠡᠶ᠎ᠡ  ᠶᠢᠨ ᠦᠨᠡᠮᠯᠡᠯ ᠦᠨ ᠨᠣᠮᠧᠷ', //身份证号
    "job_status": "ᠠᠵᠢᠯᠯᠠᠵᠤ ᠪᠠᠶᠭ᠎ᠠ ᠪᠠᠢᠳᠠᠯ",//在职状态
    "see": "ᠦᠵᠡᠬᠦ",//查看
    "nationality": 'ᠤᠯᠤᠰ ᠤᠨ ᠬᠠᠷᠢᠶ᠎ᠠ', //国籍
    "student_political_status": 'ᠤᠯᠤᠰ ᠲᠥᠷᠥ ᠶᠢᠨ ᠪᠠᠶᠢᠳᠠᠯ', //政治面貌
    "workstatus": 'ᠠᠵᠢᠯ ᠤᠨ ᠪᠠᠶᠢᠷᠢ ᠳᠤ ᠪᠠᠶᠢᠭ᠎ᠠ ᠪᠠᠶᠢᠳᠠᠯ ', //在岗状态
    "top_title": 'ᠬᠠᠮᠤᠭ ᠦᠨᠳᠦᠷ ᠮᠡᠷᠭᠡᠵᠢᠯ ᠦᠨ ᠴᠣᠯᠠ', //最高职称
    "highest_education": 'ᠬᠠᠮᠤᠭ ᠦᠨᠳᠦᠷ ᠰᠤᠷᠤᠯᠭ᠎ᠠ  ᠶᠢᠨ ᠨᠠᠮᠲᠠᠷ', //最高学历
    "teacher_introduction": "ᠪᠠᠭᠰᠢ  ᠶᠢᠨ ᠲᠠᠨᠢᠯᠴᠠᠭᠤᠯᠭ᠎ᠠ",//教师简介
    "email": 'ᠢᠮᠧᠯ ᠦᠨ ᠬᠠᠶ᠋ᠢᠭ', //邮箱
    "place_of_origin": 'ᠤᠬ ᠨᠤᠲᠤᠭ', //籍贯
    "native_heath": 'ᠲᠥᠷᠥᠭᠰᠡᠨ ᠨᠤᠲᠤᠭ ', //出生地
    "present_address": 'ᠣᠲᠣ ᠶᠢᠨ ᠰᠠᠭᠤᠭ᠎ᠠ ᠭᠠᠵᠠᠷ ᠤᠨ ᠬᠠᠶ᠋ᠢᠭ', //现住址
    "household_registration": 'ᠡᠷᠦᠬᠡ᠋ ᠠᠮᠠ ᠪᠠᠶᠢᠭ᠎ᠠ ᠭᠠᠵᠠᠷ', //户籍所在地
    "province": 'ᠮᠤᠵᠢ', //省份
    "city": 'ᠬᠤᠲᠠ', //城市
    "county": 'ᠬᠣᠰᠢᠭᠤ ᠰᠢᠶᠠᠨ / ᠣᠷᠣᠨ', //旗县/区
    "file_operation": "ᠠᠵᠢᠯᠯᠠᠬᠤ",//操作
    "student_name": 'ᠰᠤᠷᠤᠭᠴᠢ  ᠶᠢᠨ ᠨᠡᠷ᠎ᠡ', //学生姓名	
    "student_ehnic": 'ᠦᠨᠳᠦᠰᠦᠲᠡᠨ', //民族
    "trusted_individuals": "ᠢᠲᠡᠭᠡᠮᠵᠢᠯᠡᠭᠰᠡᠨ ᠬᠤᠪᠢ ᠬᠥᠮᠥᠨ", //信任的个人
    "published_resources": " ᠡᠬᠢ ᠰᠤᠷᠪᠤᠯᠵᠢ ᠬᠤᠪᠢᠶᠠᠯᠴᠠᠪᠠ",//发布了资源
    "the_cell_phone": "ᠭᠠᠷ ᠤᠲᠠᠰᠤᠨ ᠳᠡᠭᠡᠷ᠎ᠡ ᠦᠵᠡᠬᠦ ",//在手机上
    "research_institute": "ᠡᠷᠬᠢᠯᠡᠭᠴᠢ ᠪᠠᠶᠢᠭᠤᠯᠭ᠎ᠠ ᠄ ᠥᠪᠥᠷ ᠮᠣᠩᠭᠣᠯ  ᠤᠨ ᠣᠷᠬᠣᠨ ᠮᠣᠩᠭᠣᠯ ᠬᠡᠯᠡ ᠪᠢᠴᠢᠭ  ᠦᠨ ᠵᠠᠩᠭᠢᠴᠢᠯᠡᠯᠳᠡ  ᠶᠢᠨ ᠠᠺᠠᠳ᠋ᠧᠮᠢ ",//研究院
    "technical_support": "ᠮᠡᠷᠭᠡᠵᠢᠯ  ᠦᠨ ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠡ ᠄ᠦᠪᠦᠷ ᠮᠤᠩᠭᠤᠯ ᠤᠨ ᠤᠷᠬᠤᠨ ᠰᠢᠨᠵᠢᠯᠡᠭᠡ ᠮᠡᠷᠬᠡᠵᠢᠯ ᠦᠨ ᠬᠢᠵᠠᠭᠠᠷᠳᠤ ᠺᠤᠮᠫᠠᠨᠢ",//技术支持
}