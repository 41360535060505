import gql from 'graphql-tag';
const newTeachers = gql`
    query($page: Int!, $limit: Int!,$condition:TeacherCondition){
        teachersNew(page: $page, limit: $limit,condition:$condition){
            code
            msg
            total
            data {
                id
                user{
                    id
                    username
                    idcard
                }
                name{
                    zh
                }
            }
        }
    }
`;
const newTeachersData = gql`
query($page: Int!, $limit: Int!, $condition: TeacherCondition){
  teachersNew(page: $page, limit: $limit, condition: $condition){
    code
    msg
    total
    data{
      name {
        en
        mn
        zh
      }
      id
      idcard
      imgphotos
      gender{
        dictionary_value
        id
        name{
          zh
          en
          mn
        }
      }
      user{
        id
        school{
          id
        }
      }
      nation{
        dictionary_value
        id
        name{
          zh
          mn
          en
        }
      }
      nationality{
        dictionary_value
        id
        name{
          zh
          mn
          en
        }
      }
      political_type{
        dictionary_value
        id
        name{
          zh
          mn
          en
        }
      }
      work_status{
        dictionary_value
        id
        name{
          zh
          mn
          en
        }
      }
      job_title{
        dictionary_value
        id
        name{
          zh
          mn
          en
        }
      }
      education{
        dictionary_value
        id
        name{
          zh
          mn
          en
        }
      }
      telnumber
      birthday
      introduction{
        zh
        mn
        en
      }
      periods{
        id
        name{
          zh
          mn
          en
        }
      }
      grades{
        id
        name{
          zh
          mn
          en
        }
      }
      subjects{
        id
        subject_name{
          zh
          mn
          en
        }
      }
    }
  }
} `;
/**
 * 查询教职工列表数据
 * @param page 分页`
 * @param limit 每页数据量
 */

const queryTeacherList = gql`
	query ($page: Int!,$limit: Int!, $condition: TeacherCondition){
		teachers (
        page: $page,
        limit: $limit,
        condition: $condition
			){
	      		code
            msg
            total
            data{ 
                name {
                  en
                  mn
                  zh
                }
                id
                idcard
                imgphotos
                gender{
                  dictionary_value
                  id
                  name{
                    zh
                    en
                    mn
                  }
                }
                user{
                      id
                      school{
                        id
                      }
                  }
                nation{
                  dictionary_value
                  id
                  name{
                    zh
                    mn
                    en
                  }
                }
                nationality{
                  dictionary_value
                  id
                  name{
                    zh
                    mn
                    en
                  }
                }
                political_type{
                  dictionary_value
                  id
                  name{
                    zh
                    mn
                    en
                  }
                }
                work_status{
                  dictionary_value
                  id
                  name{
                    zh
                    mn
                    en
                  }
                }
                job_title{
                  dictionary_value
                  id
                  name{
                    zh
                    mn
                    en
                  }
                }
                education{
                  dictionary_value
                  id
                  name{
                    zh
                    mn
                    en
                  }
                }
                telnumber
                birthday
                introduction{
                  zh
                  mn
                  en
                }
                periods{
                  mark
                  id
                  name{
                    zh
                    mn
                    en
                  }
                }
                grades{
                  mark
                  id
                  name{
                    zh
                    mn
                    en
                  }
                }
                subjects{
                  subject_number
                  id
                  subject_name{
                    zh
                    mn
                    en
                  }
                }
			}
		}
	}
`
// /**
//  * 教职工修改数据
//  */
const findeTeacherData = gql`
	query ($id: Int!){
		teacher (id: $id) {
      id
			name{
				mn
				en
				zh
			}
      idcard
      user{
        id
      }
			gender {
				dictionary_value
				name{
          zh
          mn
          en
				}
            }
			imgphotos
			telnumber
			nationality{
				dictionary_value
				name{
          zh
          mn
          en
				}
			}
			political_type{
				dictionary_value
				name{
          zh
          mn
          en
				}
			}
			work_status{
				dictionary_value
				name{
					zh
					mn
					en
				}
			}
			birthday
			job_title{
				dictionary_value
				name{
          zh
          mn
          en
				}
			}
			education{
				dictionary_value
				name{
          zh
          mn
          en
				}
			}
			degree{
				dictionary_value
				name{
          zh
          mn
          en
				}
			}
			master_supervisor{
				dictionary_value
				name{
					zh
				}
			}
			teach{
				dictionary_value
				name{
					zh
				}
			}
			nation{
				dictionary_value
				name{
					zh
				}
			}
			introduction {
				mn
				zh
				en
			}
			periods{
				id
				name{
					zh
				}
			}
			grades{
				id
				name{
					zh
				}
			}
			subjects{
				id
				subject_name{
					zh
				}
			}
			school{
				name{
					zh
				}
			}
		}
	}
`
/**
 * 分配管理员
 */
const addUser = gql`
	mutation addUser($inputData: UserInput){
		addUser(inputData: $inputData){
			code
			msg
		}
	}
`;
const addTeacherData = gql`
	mutation addTeacher($inputData: TeacherInput){
		addTeacher(inputData: $inputData){
			code
			msg
		}
	}
`
export {
  newTeachers,
  newTeachersData,
  addUser,
  addTeacherData,
  queryTeacherList,
  findeTeacherData
}