import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { RequestService } from '../request/request.service';
import { BusinessService } from '../business/business.service';
import { MODELLIST, MENUSLIST, MENUSPATH, MENUSTYPE } from 'src/app/utils/constant';
import Config from '../../config/index';
import { BaseService } from '../base/base.service';
import { OrhonUtilService, SettingService } from 'ngx-orhon-mgl-component';

import { PaperServiceService } from '../paper/paper-service.service';


@Injectable({
	providedIn: 'root'
})
export class PermissionsService {
	// 获取用户信息api地址
	public userInfoApi: string = 'base/systemctl/system/get/userinfo';
	// 获取模块列表api地址
	public modulesApi: string = 'base/get/newUser/module/list';
	// 获取菜单列表api地址
	public userMenusApi: string = 'base/get/newUser/menus/list';
	themeColor: string = '';
	contrastColor: string = '';
	constructor(
		private router: Router,
		private apollo: Apollo,
		private http: RequestService,
		private business: BusinessService,
		private base: BaseService,
		private util: OrhonUtilService,
		private settings: SettingService,
		private paperService: PaperServiceService
		// private https: RequestService
		// private notification:NzNotifica
	) { }
	//	判断是否登录
	public canActivate(value: any) {
		const val = localStorage.getItem('token');
		if (val !== null && val) {

			this.business.freshUserInfo(this.business.getItem('userInfo'));
			return this.canActiveRoute(value)
		} else {
			this.router.navigate(['login']);
			return false
		}
	}

	//判断是否有权限访问路由
	public canActiveRoute(val: any) {
		console.log('val.url', val.url);

		if (val.url.includes('/pages/prepare-course/my-prepare-edit')) {
			return true;
		}
		if (val.url.includes('/pages/resources/resources-detail')) {
			return true;
		}
		if (val.url.includes('/pages/prepare-course/prepare-detail')) {
			return true
		}
		if (val.url.includes('/pages/single/class-detail')) {
			return true
		}
		if (val.url.includes('/pages/single/cloud-square-detail')) {
			return true
		}
		if (val.url.includes('/pages/teacher/information-detail')) {
			return true
		}
		if (val.url.includes('/panel/subject-homepage')) {
			return true;
		}
		if (val.url.includes('/pages/material-library/home')) {
			return true;
		}
		if (val.url.includes('/pages/single/add')) {
			return true;
		}
		if (val.url.includes('/panel/information-list')) {
			console.log('/panel/information-list');

			return true;
		}
		if (val.url.includes('/home')) {
			return true;
		} else {
			const url_list = localStorage.getItem('menu-path') ? JSON.parse(localStorage.getItem('menu-path')) : null;
			let index = val.url.lastIndexOf('?');
			let str = val.url;
			if (index >= 0) {
				str = val.url.substring(0, index);
			}
			const value = url_list.find(item => str === item);
			if (value) {
				return true;
			} else {
				this.router.navigateByUrl('/home');
				return true
			}
		}
	}

	//获取token
	getToken(username: string, password: string) {
		const data = {
			username,
			password
		}
		return new Observable(observer => {
			this.http.post('oauth/oauth-login', data).subscribe(
				(res: any) => {
					if (res) {
						observer.next(res);
						this.business.setItem('token', res.access_token);
						this.getUserList()
					} else {
						observer.error(res)
					}
				},
				error => {
					observer.error(error)
				},
			)
		})
	}
	getUserList() {
		this.getUserInfo();
		this.base.getGradeData();
		this.base.getSubjectData();
		this.base.getPeriodData();
	}
	//获取用户信息
	getUserInfo() {

		this.http.get(this.userInfoApi).subscribe((res: any) => {
			if (res && res.code === 200) {
				this.business.setItem('userInfo', res.data);
				this.business.freshUserInfo(res.data);
				this.colorChange();
				this.getModeType()
				this.getModeList();
				this.paperService.getToken();
			} else {
				//	错误信息
			}
		})
	}
	//设置主题是
	async colorChange() {


		this.business.getcolors().subscribe(async item => {
			console.log(item);
			let colors = this.business.getItem('userInfo');
			const backgroundColors = colors.school ? (colors.school.settings ? colors.school.settings.themeSettings.zh : '#206F53') : '';
			const color1 = await this.util.color(backgroundColors, 0.05, true);
			const color2 = await this.util.color(backgroundColors, 0.8, true);
			const color3 = await this.util.color(backgroundColors, 0.38, true);
			const theme = {
				'--omc-color-primary': backgroundColors,
				'--omc-color-primary-rgb': color1,
				'--omc-color-primary-contrast': '#ffffff',
				'--omc-color-primary-contrast-rgb': color2,
				'--omc-color-primary-background-rgb': color3
			};
			this.settings.setActiveTheme(theme);

		})

	}
	getModeType() {
		this.http.get('base/get/newUser/moduleType/list?lang_name=mn_pc').subscribe((res: any) => {
			console.log('munes', res);
			let munes = res.data.filter((item: any) => JSON.parse(item.name).zh !== '其他');
			this.business.setItem(MENUSTYPE, munes);
		})
	}
	//获取模块列表
	getModeList() {
		this.http.get(this.modulesApi).subscribe((res: any) => {
			if (res && res.code === 200) {
				//	处理过滤pc端显示的模块
				let app_model = [];
				res.data.forEach((item: any) => {
					if (item.clients.length > 0) {

						const array_res = item.clients[0].split(',');
						const res: any = array_res.find(value => value === 'mn_pc');
						if (res) {
							if (item.mark === 'panel') {
								return false
							} else {
								app_model.push(item)
							}
						}
					}
				});
				this.business.setItem(MODELLIST, app_model);
				this.getMenusList();
			} else {
				//	错误信息
			}
		})
	}
	//获取菜单列表
	getMenusList() {
		this.http.get(this.userMenusApi).subscribe((res: any) => {
			if (res && res.code === 200) {
				let menuPath = [];
				let paths = []
				res.data.map((item: any) => {
					if (item.clients.length > 0) {
						const res: any = item.clients.find(value => value === 'mn_pc');
						if (res) {

							menuPath.push(item.path)
							paths.push(item)
						}
					}
				})
				menuPath = menuPath.concat(Config.white_path);
				this.business.setItem(MENUSLIST, paths);
				this.business.setItem(MENUSPATH, menuPath);
				this.loginSuccess()
			} else {
				//错误信息
			}
		})
	}
	//登录成功
	loginSuccess() {
		this.apollo.getClient().resetStore()
		this.router.navigate(['home'])
	}
	//退出
	public logOut() {
		this.apollo.getClient().clearStore();
		localStorage.clear();
		this.router.navigate(['login'])
	}
}
