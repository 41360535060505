module.exports = {
    "tags_name":"ᠲᠥᠷᠥᠯ ᠵᠦᠢᠯ ᠦᠨ ᠨᠡᠷᠡᠢᠳᠦᠯ",//标签名称
    "tags_prent":"ᠲᠥᠷᠥᠯ ᠵᠦᠢᠯ ᠦᠨ ᠳᠡᠭᠡᠳᠦ ᠳᠡᠰ",//标签父级
    "tag_entities":"ᠲᠥᠷᠥᠯ ᠵᠦᠢᠯ ᠦᠨ ᠳᠤᠤᠷᠠᠳᠤ ᠳᠡᠰ",//标签子级
    "tag_order":"ᠳᠠᠷᠠᠭᠠᠯᠠᠯ",//顺序
    "tag_slug":"ᠥᠬᠡᠷ᠎ᠡ ᠨᠡᠷᠡᠢᠳᠦᠯ",//别名
    "tag_lavel":"ᠲᠥᠷᠥᠯ ᠵᠦᠢᠯ ᠦᠨ ᠳᠡᠰ ",//标签级别
    "tag_add":"ᠲᠥᠷᠥᠯ ᠵᠦᠢᠯ ᠨᠡᠮᠡᠬᠦ", //添加标签
    "index_xitong": 'ᠰᠢᠰᠲ᠋ᠧᠮ',//系统
    "personal": 'ᠬᠤᠪᠢ ᠶᠢᠨ',//个人
    "parend_id": 'ᠳᠤᠤᠷᠠᠳᠤ ᠳᠡᠰ', //下一级别
    "type_add":"ᠲᠥᠷᠥᠯ ᠵᠦᠢᠯ ᠨᠡᠮᠡᠬᠦ",//添加分类
    "type_select":"ᠲᠥᠷᠥᠯ ᠵᠦᠢᠯ ᠰᠤᠩᠭᠤᠬᠤ",//选择分类
    "type_name":"ᠲᠥᠷᠥᠯ ᠵᠦᠢᠯ  ᠦᠨ ᠨᠡᠷᠡᠢᠳᠦᠯ",//分类名称
    "ttype_prent":"ᠲᠥᠷᠥᠯ ᠵᠦᠢᠯ  ᠦᠨ  ᠳᠡᠭᠡᠳᠦ ᠳᠡᠰ",//分类父级
    "school": 'ᠰᠤᠷᠭᠠᠭᠤᠯᠢ ᠶᠢᠨ',//学校
    "not_picture":"ᠰᠦᠯᠵᠢᠶᠡᠨ ᠳᠠᠪᠠᠭ ᠵᠢᠷᠤᠭ ᠦᠬᠡᠢ ᠪᠠᠢᠨ᠎ᠠ ᠂ ᠠᠴᠢᠶᠠᠯᠠᠭᠠᠷᠠᠢ ︕",//网盘没有图片请上传
}