import {NgModule} from '@angular/core';
import {HttpClientModule, HttpHeaders} from '@angular/common/http';
import {ApolloModule, Apollo} from 'apollo-angular';
import {HttpLinkModule, HttpLink} from 'apollo-angular-link-http';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {BrowserModule} from '@angular/platform-browser';
import Config from './config/gqlhost';
import {ApolloLink, concat} from 'apollo-link';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        ApolloModule,
        HttpLinkModule
    ]
})
export class GraphQLModule {

    constructor(apollo: Apollo, httpLink: HttpLink) {
        const authMiddleware = new ApolloLink((operation, forward) => {
            let headers = {};
            const token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
            if (token) {
                headers = new HttpHeaders().set('token', token);
            }
            operation.setContext({
                headers,
            });
            return forward(operation);
        });

        /**
         * 默认接口
         */
        const baseDefault = httpLink.create({uri: Config.hostGqlDefault});
        apollo.createDefault({
            link: concat(authMiddleware, baseDefault),
            cache: new InMemoryCache({
                addTypename: false,
            }),
        });

        /**
         * 扩展接口
         */
        Config.hostGql.map(el => {
            const httpL = httpLink.create({uri: el.url});
            apollo.createNamed(el.name, {
                link: concat(authMiddleware, httpL),
                cache: new InMemoryCache({
                    addTypename: false,
                }),
            });
        });
    }

}
