module.exports = {
    "prepare_home": { //备课首页
        "lesson_mission": "ᠪᠡᠯᠡᠳᠬᠡᠮᠵᠢ  ᠶᠢᠨ ᠡᠭᠦᠷᠭᠡ",//我的任务
        "plan_completed": "ᠪᠡᠶᠡᠯᠡᠭᠦᠯᠦᠭᠰᠡᠨ ᠲᠥᠯᠥᠪᠯᠡᠭᠡ  ᠶᠢᠨ ᠲᠣᠭ᠎ᠠ ",//已完成计划数
        "unfinished_plan": "ᠪᠡᠶᠡᠯᠡᠭᠦᠯᠦᠭᠰᠡᠨ ᠦᠭᠡᠢ ᠲᠥᠯᠥᠪᠯᠡᠭᠡ  ᠶᠢᠨ ᠲᠣᠭ᠎ᠠ",//未完成计划数
        "lesson_total_number": "ᠪᠡᠯᠡᠳᠬᠡᠮᠵᠢ  ᠶᠢᠨ ᠲᠣᠭ᠎ᠠ",//备课总数  
        "lesson_group": "ᠬᠢᠴᠢᠶᠡᠯ ᠪᠡᠯᠡᠳᠬᠡᠬᠦ ᠳᠤᠭᠤᠢᠯᠠᠡᠭ᠍",//备课组
        "subject_group": "ᠬᠢᠴᠢᠶᠡᠯ ᠳᠦᠷᠦᠯ ᠦᠨ ᠳᠤᠭᠤᠢᠯᠠᠩ",//学科组
        "personal": "ᠬᠤᠪᠢ ᠬᠦᠮᠦᠨ",//个人
        "organization": "ᠰᠠᠯᠠᠭ᠎ᠠ ᠮᠦᠴᠢᠷ",//组织
        "summary_success": "ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠳ᠋ᠦᠩᠨᠡᠭᠰᠡᠨ",//总结成功
        "question_bank": "ᠰᠡᠳᠦᠪ ᠦᠨ ᠴᠣᠩᠬᠣ",
        "answer_bank": "ᠬᠠᠷᠢᠭᠤᠯᠲᠠ ᠶᠢᠨ ᠴᠣᠩᠬᠣ",
        "question_type": "ᠰᠡᠳᠦᠪ ᠦᠨ ᠬᠡᠯᠪᠡᠷᠢ",//题型
        "set_answers": "ᠬᠠᠷᠢᠭᠤᠯᠲᠠ ᠪᠣᠯᠭᠠᠬᠤ",//设置答案
        "answers": "ᠬᠠᠷᠢᠭᠤᠯᠲᠠ",//答案
        "score": "ᠬᠤᠪᠢ",//分数
        "puestion_number": "ᠬᠤᠪᠢᠶᠠᠷᠢ ᠭᠠᠷᠴᠠᠭ ᠤᠨ ᠲᠣᠭ᠎ᠠ",//子级数量
    },
    "prepare_plan": { //备课计划
        "add": "ᠰᠢᠨ᠎ᠡ  ᠪᠡᠷ ᠨᠡᠮᠡᠬᠦ ",//新增

        "add_design": "ᠲᠥᠰᠦᠪ ᠪᠠᠶᠢᠭᠤᠯᠬᠤ ",//新增教学设计
        "my_lesson_preparation": "ᠪᠡᠯᠡᠳᠬᠡᠮᠵᠢ",//我的备课
        "my_lesson": "ᠬᠢᠴᠢᠶᠡᠯ  ᠦᠨ ᠪᠡᠯᠡᠳᠬᠡᠮᠵᠢ",//备课
        "lesson_personal": "ᠬᠢᠴᠢᠶᠡᠯ ᠪᠡᠯᠡᠳᠬᠡᠭᠴᠢ",//备课人
        "published_lesson_preparation": "ᠨᠡᠢᠳᠡᠯᠡᠭᠰᠡᠨ ᠡᠬᠦᠷᠬᠡ",//已发布的备课计划任务
        "drafts": "ᠨᠤᠤᠷᠠᠭ",//草稿箱
        "lesson_preparation": "ᠬᠢᠴᠢᠶᠡᠯ ᠪᠡᠯᠡᠳᠬᠡᠬᠦ",//备课
        "lesson_name": "ᠭᠠᠷᠴᠠᠭ",//备课名称
        "delete_success": "ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠬᠠᠰᠤᠭᠰᠠᠨ", //删除成功
        "lesson_title": "ᠭᠠᠷᠴᠠᠭ",//备课标题
        "lesson_type": "ᠪᠡᠯᠡᠳᠬᠡᠮᠵᠢ  ᠶᠢᠨ ᠲᠥᠷᠥᠯ ᠵᠦᠢᠯ",//备课类型
        "semester": "ᠰᠤᠷᠤᠯᠭ᠎ᠠ ᠶᠢᠨ ᠬᠤᠭᠤᠴᠠᠭ᠎ᠠ",//学期
        "preparation_types": "ᠪᠡᠯᠡᠳᠬᠡᠮᠵᠢ  ᠶᠢᠨ ᠲᠥᠷᠥᠯ",//备课种类
        "lesson_time": "ᠪᠡᠯᠡᠳᠬᠡᠮᠵᠢ  ᠶᠢᠨ ᠴᠠᠭ", //备课时间
        "lesson_preparation_plan": "ᠵᠢᠭᠠᠬᠤ ᠰᠤᠷᠬᠤ ᠲᠥᠯᠥᠪᠯᠡᠭᠡ", //教学备课计划
        "lesson_preparation_completed": "ᠨᠢᠭᠡᠨᠳᠡ ᠪᠡᠶᠡᠯᠡᠭᠦᠯᠦᠭᠰᠡᠨ ᠬᠢᠴᠢᠶᠡᠯ ᠦᠨ ᠪᠡᠯᠡᠳᠬᠡᠯ",//已完成的备课
        "task_title": "ᠭᠠᠷᠴᠠᠭ", //任务标题
        "task_time": "ᠴᠠᠭ", //任务时间
        "question_title": "ᠠᠵᠢᠯᠯᠠᠬᠤ ᠰᠠᠨᠠᠭᠤᠯᠭ᠎ᠠ",//题库提示
        "task_number": "ᠡᠬᠦᠷᠭᠡ ᠶᠢᠨ ᠲᠤᠭ᠎ᠠ",//任务数
        "content_type": "ᠠᠭᠤᠯᠭ᠎ᠠ ᠶᠢᠨ ᠲᠦᠷᠦᠯ",//备课内容类型
        "see": "ᠦᠵᠡᠬᠦ",//查看
        "file_operation": "ᠠᠵᠢᠯᠯᠠᠬᠤ",//操作  
        "collective_lesson": "ᠬᠠᠮᠲᠤ ᠶᠢᠨ ᠪᠡᠯᠡᠳᠬᠡᠮᠵᠢ",//集体备课
        "personal_lesson": "ᠬᠤᠪᠢ ᠶᠢᠨ ᠪᠡᠯᠡᠳᠬᠡᠮᠵᠢ",//个人备课
        "teacher_name": "ᠪᠠᠭᠰᠢ ᠶᠢᠨ ᠨᠡᠷ᠎ᠡ",//教师名称
        "completion_status": "ᠪᠡᠶᠡᠯᠡᠭᠦᠯᠬᠦ ᠪᠠᠢᠳᠠᠯ",//完成状态
        "completion_situation": "ᠪᠠᠶᠡᠯᠡᠭᠦᠯᠦᠭᠰᠡᠨ ᠬᠡᠮᠵᠢᠶ᠎ᠡ",//完成情况
        "completed": "ᠪᠡᠶᠡᠯᠡᠭᠦᠯᠦᠭᠰᠡᠨ",//已完成
        "unfinished": "ᠪᠡᠶᠡᠯᠡᠭᠦᠯᠦᠭᠡᠳᠦᠶ",//未完成
        "edit": "ᠵᠠᠰᠠᠬᠤ",//修改
        "delete": "ᠬᠠᠰᠤᠬᠤ",//删除
        "publish": "ᠨᠡᠶᠢᠲᠡᠯᠡᠬᠦ",//发布
        "complation": "ᠪᠡᠶᠡᠯᠡᠭᠦᠯᠬᠦ", //去完成
        "start_time": 'ᠡᠬᠢᠯᠡᠬᠦ ᠴᠠᠭ', //开始时间
        "end_time": 'ᠲᠡᠭᠦᠰᠬᠦ ᠴᠠᠭ', //结束时间
        "finish_the_task": "ᠡᠭᠦᠷᠭᠡ ᠪᠡᠨ ᠪᠡᠶᠡᠯᠡᠭᠦᠯᠬᠦ",//完成任务
        "private_deposit": "ᠬᠤᠪᠢ  ᠳᠤ  ᠪᠡᠨ ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ",//存私有
        "organization_name": "ᠵᠣᠬᠢᠶᠠᠨ ᠪᠠᠶᠢᠭᠤᠯᠤᠯᠲᠠ ᠶᠢᠨ ᠨᠡᠷᠡᠶᠢᠳᠦᠯ", //组织名称
        "teacher_plan": "ᠵᠢᠭᠠᠬᠤ ᠰᠤᠷᠬᠤ ᠲᠥᠯᠥᠪᠯᠡᠭᠡ",//教学计划
        "teacher_plan_name": "ᠭᠠᠷᠴᠠᠭ",//教学计划名称
        "founder": "ᠨᠡᠷ᠎ᠡ", //创建人
        "update_time": "ᠴᠠᠭ",//更新时间
        "write_summary": "ᠳ᠋ᠦᠩᠨᠡᠯᠲᠡ ᠪᠢᠴᠢᠬᠦ",//写总结
        "organized_system": "ᠬᠠᠷᠢᠶ᠎ᠠ ᠰᠠᠯᠠᠭ᠎ᠠ ᠮᠥᠴᠢᠷ",//所属组织
        "content": "ᠠᠭᠤᠯᠭ᠎ᠠ",//内容
        "content_requirements": "ᠠᠭᠤᠯᠭ᠎ᠠ ᠱᠠᠭᠠᠷᠳᠠᠯᠭ᠎ᠠ᠎",//内容要求
        "content_requirements_required": "ᠠᠭᠤᠯᠭ᠎ᠠ ᠱᠠᠭᠠᠷᠳᠠᠯᠭ᠎ᠠ᠎ ᠪᠡᠨ ᠪᠢᠴᠢᠭᠡᠷᠡᠢ",//请输入内容要求
        "lesson_plan_add": "ᠵᠢᠭᠠᠬᠤ ᠰᠤᠷᠬᠤ ᠲᠥᠯᠥᠪᠯᠡᠭᠡ ᠨᠡᠮᠡᠬᠦ",//添加教学计划
        "summary": "ᠳ᠋ᠦᠩᠨᠡᠯᠲᠡ",//总结
        "release_square": "ᠲᠠᠯᠠᠪᠠᠢ ᠳᠤ ᠨᠡᠶᠢᠲᠡᠯᠡᠬᠦ",//发布广场
        "delete_confirmation": "ᠬᠠᠰᠤᠬᠤ ᠨᠤᠲᠠᠯᠠᠯ",//删除确认
        "sure_delete_data": "ᠡᠳᠡᠭᠡᠷ ᠲᠣᠭ᠎ᠠ ᠪᠠᠷᠢᠮᠲᠠ  ᠢ ᠪᠦᠷ  ᠬᠠᠰᠤᠨ᠎ᠠ ᠦᠦ ？",//您确定要删除这些数据吗
        "the_delete_data": "ᠲᠤᠰ ᠵᠣᠷᠪᠣᠰ ᠢ ᠬᠠᠰᠤᠬᠤ ᠦᠦ ？",//确定要删除这条数据
        "determine": 'ᠳᠡᠭᠡ', // 确定
        "cancel": 'ᠦᠭᠡᠢ', // 取消
        "annex": "ᠳᠠᠭᠠᠯᠳᠤᠭᠤᠯᠤᠯ ", //附件
        "please_lesson_title": "ᠭᠠᠷᠴᠠᠭ  ᠢᠶᠠᠨ ᠪᠢᠴᠢᠭᠡᠷᠡᠢ",//  请添加备课标题
        "please_lesson_plan": "ᠲᠥᠯᠥᠪᠯᠡᠭᠡ  ᠪᠡᠨ ᠰᠣᠩᠭᠣᠭᠠᠷᠠᠢ",//请选择备课计划
        "please_organized": "ᠳᠤᠭᠤᠶᠢᠯᠠᠩ ᠢᠶᠡᠨ ᠰᠣᠩᠭᠣᠭᠠᠷᠠᠢ ", //请选择部门
        "please_semester": "ᠰᠤᠷᠤᠯᠭ᠎ᠠ ᠶᠢᠨ ᠬᠤᠭᠤᠴᠠᠭ᠎ᠠ ᠶᠢ ᠰᠣᠩᠭᠣᠭᠠᠷᠠᠶ",//请选择学期
        "release_status": "ᠨᠡᠶᠢᠲᠡᠯᠡᠬᠦ ᠪᠠᠢᠳᠠᠯ",//发布状态
        "please_release_status": "ᠨᠨᠡᠶᠢᠲᠡᠯᠡᠬᠦ ᠬᠡᠯᠪᠡᠷᠢ ᠢᠶᠡᠨ ᠰᠣᠩᠭᠣᠭᠠᠷᠠᠶ",//请选择发布状态
        "please_lesson_type": "ᠪᠡᠯᠡᠳᠬᠡᠮᠵᠢ  ᠶᠢᠨ ᠲᠥᠷᠥᠯ ᠢᠶᠡᠨ ᠰᠣᠩᠭᠣᠭᠠᠷᠠᠶ",//请选择备课类型
        "additional_information": "ᠨᠡᠮᠡᠯᠲᠡ ᠵᠠᠩᠭᠢ",//附加信息
        "finish_the_task": "ᠡᠭᠦᠷᠭᠡ ᠪᠡᠨ ᠪᠡᠶᠡᠯᠡᠭᠦᠯᠬᠦ",//完成任务
        "save_draft": "ᠨᠤᠤᠷᠠᠭ ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ",//存草稿
        "please_content": "ᠠᠭᠤᠯᠭ᠎ᠠ  ᠪᠠᠨ ᠪᠢᠴᠢᠭᠡᠷᠡᠢ",//请输入内容
        "please_file": "ᠹᠠᠶᠢᠯ  ᠢᠶᠡᠨ ᠰᠣᠩᠭᠣᠭᠠᠷᠠᠶ",//请选择文件
        "please_course": "ᠬᠢᠴᠢᠶᠡᠯ ᠢᠶᠡᠨ ᠰᠣᠩᠭᠣᠭᠠᠷᠠᠶ",//请选择课程
        "system_ownership": "ᠰᠢᠰᠲ᠋ᠧᠮ  ᠦᠨ ᠱᠣᠰᠢᠭ᠎ᠠ",//系统所有
        "select_grade_on_subject": "ᠣᠨ ᠪᠠ ᠬᠢᠴᠢᠶᠡᠯ ᠲᠥᠷᠥᠯ  ᠢᠶᠡᠨ ᠰᠣᠩᠭᠣᠭᠠᠷᠠᠶ",//请选择年级和学习
        "private": "ᠬᠤᠪᠢ  ᠶᠢᠨ ᠱᠣᠰᠢᠭ᠎ᠠ ",//私有
        "set_label": "ᠱᠤᠰᠢᠭ᠎ᠠ ᠬᠠᠳᠠᠬᠤ ",//设置标签
        "printing": 'ᠳᠠᠷᠤᠮᠠᠯᠯᠠᠬᠤ',//打印
        "saved": "ᠨᠢᠭᠡᠨᠲᠡ ᠬᠠᠳᠠᠭᠠᠯᠠᠪᠠ",//已保存
        "saving": "ᠬᠠᠳᠠᠭᠠᠯᠠᠵᠤ ᠪᠠᠶᠢᠨ᠎ᠠ",//正在保存中
        "not_title": "ᠭᠠᠷᠴᠠᠭ ᠦᠭᠡᠢ ",//无标题
        "my_task": "ᠡᠭᠦᠷᠭᠡ",//我的任务
        "explanation": "ᠵᠠᠳᠠᠯᠤᠯᠲᠠ",//分析
    },
    "prepare_task": { //备课任务
        "my_task": "ᠡᠭᠦᠷᠭᠡ",//我的任务
        "unfinished_tasks": "ᠪᠡᠶᠡᠯᠡᠬᠦᠯᠦᠭᠡᠳᠦᠢ ᠡᠬᠦᠷᠬᠡ",//未完成的任务
        "completed_tasks": "ᠪᠡᠶᠡᠯᠡᠬᠦᠯᠦᠭᠰᠨ ᠡᠬᠦᠷᠭᠡ",//已完成的任务
        "please_enter_task_title": "ᠭᠠᠷᠴᠠᠭ ᠢᠶᠠᠨ ᠪᠢᠴᠢᠭᠡᠷᠡᠢ", //请输入任务标题

    }
}