import {Injectable} from '@angular/core';
import {BusinessService} from '../business/business.service';
import {Apollo} from 'apollo-angular';
import {regionsList} from 'src/app/graphQl/base/regions';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor(private business: BusinessService,
                private apollo: Apollo) {
    }

    getRegions(callback?: any) {
        const that = this;
        that.business.getIndexedDb('regions').subscribe((res: any) => {
            if (res.code === 200) {
                callback(res);
            } else {
                setTimeout(() => {
                    that.setRegions();
                }, 2000);
            }
        });
    }

    setRegions(): void {
        const that = this;
        that.apollo.watchQuery({
            query: regionsList,
            variables: {
                page: 1,
                limit: 10000,
            }
        }).valueChanges.subscribe((res: any) => {
            if (res.data.regions.code === 200) {
                const province = res.data.regions.data.filter((el: any) => el.level === 0);
                const city = res.data.regions.data.filter((el: any) => el.level === 1);
                const area = res.data.regions.data.filter((el: any) => el.level === 2);
                const data = province.amp((el: any) => {
                    // tslint:disable-next-line:radix
                    const id = parseInt(el.id);
                    const obj = {
                        label: el.name.mn ? el.name.mn : el.name.zh,
                        value: id,
                        children: [],

                    };
                    obj.children = city.filter((w: any) => el.id === w.parent.id).map((m: any) => {
                        const id = parseInt(m.id);
                        const obj1 = {
                            label: m.name.mn ? m.name.mn : m.name.zh,
                            value: id,
                            children: []
                        };
                        obj1.children = area.filter((j: any) => m.id === j.parent.id).map((n: any) => {
                            const aid = parseInt(n.id);
                            const obj2 = {
                                label: n.name.mn ? n.name.mn : n.name.zh,
                                value: aid,
                                isLeaf: true
                            };
                            return obj2;
                        });
                        return obj1;
                    });
                    return obj;
                });
                const dbData = {
                    data
                };

                this.business.setIndexedDb(dbData, 'regions').subscribe(res => {
                });
            }
        });
    }

    // 条件判断为空的去掉
    handelCondition(data: object): any {
        const obj = Object.assign({}, data);
        Object.entries(obj).forEach((el, i) => {
            if (el[1] === null || el[1] === undefined || el[1] === '') { delete obj[el[0]]; }
        });
        return obj;
    }

    handleFilterNull(data: object) {
        const hello = {};
        for (const key in data) {
            if (data[key].length !== 0 ) {
                hello[key] = data[key];
            }
        }
        return hello;
    }

    // 生成uuid
    uuid() {
        const s = [];
        const hexDigits = '0123456789abcdef';
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
        }
        s[14] = '4';
        // tslint:disable-next-line:no-bitwise
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = '-';
        const uuid = s.join('');
        return uuid;
    }

    getUser(): Observable<any> {
        return new Observable(observe => {
            const user = localStorage.getItem('userInfo');
            observe.next(JSON.parse(user));
        });
    }
}
