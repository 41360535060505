import {BrowserModule, HammerGestureConfig, HAMMER_LOADER} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {NgxOrhonMglComponentModule} from 'ngx-orhon-mgl-component';
import {NgxIndexedDBModule, DBConfig} from 'ngx-indexed-db';
import {httpInterceptorProviders} from './http-interceptors';
import {GraphQLModule} from './graphql.module';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {NgZorroAntdModule, NZ_I18N, zh_CN} from 'ng-zorro-antd';
import {AppRoutingModule} from './app-routing.module';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {AppComponent} from './app.component';
import {LoginGuard} from './guards/login/login.guard';
import {PermissionsService} from './service/permissions/permissions.service';
import {RequestService} from './service/request/request.service';
import {UtilsService} from './service/utils/utils.service';
import {CustomTranslateService} from './service/customTranslate/custom-translate.service';

import {MenuService} from './service/menu/menu.service';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import zh from '@angular/common/locales/zh';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { RouteReuseStrategy } from '@angular/router';

import { AppRouteRueseStrategy } from './app-route-reuse-strategy';
registerLocaleData(zh);

import {EditorModule} from '@tinymce/tinymce-angular';

import { ImageCropperModule } from 'ngx-image-cropper';

// import { mn } from '../assets/i18n/mn.js';
// export function HttpLoaderFactory(http: HttpClient) {
//     return new TranslateHttpLoader(http, './assets/i18n/', '.js?');
//   }

// 初始化加载Hammer.js
export class MyHammerConfig extends HammerGestureConfig {
    overrides = {
        pan: {threshould: 5},
        swipe: {
            velocity: 0.4,
            threshould: 20,
            directive: 31
        },
        press: {}
    } as any;
}

const dbConfig: DBConfig = {
    name: 'OrhonSmartCampus',
    version: 1,
    objectStoresMeta: [
        {
            store: 'regions',
            storeConfig: {keyPath: 'id', autoIncrement: false},
            storeSchema: [],
        },
    ],
};

@NgModule({
    declarations: [
        AppComponent,
        
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgxOrhonMglComponentModule,
        NgZorroAntdModule,
        GraphQLModule,
        HttpClientModule,
        NgxEchartsModule.forRoot({
            echarts,
        }),
        // TranslateModule.forRoot(),
        NgxIndexedDBModule.forRoot(dbConfig),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                //         useFactory: HttpLoaderFactory,
                useClass: CustomTranslateService,
                //         deps: [HttpClient],
            }
        }),
        FormsModule,
        BrowserAnimationsModule,
        EditorModule,
        ImageCropperModule
    ],
    providers: [
        MenuService,
        LoginGuard,
        PermissionsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestService,
            multi: true
        },
        {
            provide:RouteReuseStrategy,useClass:AppRouteRueseStrategy
        },
        {
            provide: HAMMER_LOADER,
            useClass: MyHammerConfig
        },
        UtilsService,
        {provide: NZ_I18N, useValue: zh_CN},
        httpInterceptorProviders,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
