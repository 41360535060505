import gql from 'graphql-tag';

/**
 * 查询职务列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const duitesData = gql`
	query ($page: Int!, $limit: Int! ,$id:Int,$parent_id:Int,$duties_name:String,$system_default:Int),{
		Duties (page: $page, limit: $limit,condition:{
			id:$id
			parent_id:$parent_id
			duties_name:$duties_name
			system_default:$system_default
		}){
            code
            msg
            total
            data {
				id
				duties_name {
					en
					mn
					zh
				}
				duties_order
				parent{
					id
					duties_name {
						en
						mn
						zh
					}
				}
				level
            }
		}
	}
`;
export {
    duitesData
}