import gql from 'graphql-tag';
//获取年级
const eclassData = gql`
	query ($page: Int, $limit: Int, $condition: EclassCondition){
		Eclasses (page: $page, limit: $limit, condition: $condition){
            code
            msg
            total
            data {
                id
                status
                graduated_flag
                name {
                    en
                    mn
                    zh
                }
                grade {
                    id
                    name {
                        en
                        mn
                        zh
                    }
                }
                arrives {
                    id
                    name {
                        en
                        mn
                        zh
                    }
                }
                head_teacher {
                    teacher{
                    name {
                        en
                        mn
                        zh
                    }
                    }
                }
                logo
                ioc_path
                number 
                nature{
                  id
                  name{
                    zh
                  }  
                }
                 kind_dict{
                  id
                  name{
                    zh
                  }  
                }
            }
		}
	}
`;
export {
    eclassData
}