import { Injectable } from '@angular/core';
import { BusinessService } from '../business/business.service';
import { GraphqlService } from '../graphql/graphql.service';
import { gradesNewList } from 'src/app/graphQl/base/grade';
import { GRADE, SUBJECT, PERIODS } from 'src/app/utils/constant';
import { subjectNewLists } from 'src/app/graphQl/base/subject';
import { periodList, schoolPeriods } from 'src/app/graphQl/base/period';
import { newTeachers } from 'src/app/graphQl/teacher/teacher-information';
import { dictionaysList } from 'src/app/graphQl/base/dictionary';
import { formatDate } from '@angular/common';
import { semesterData } from 'src/app/graphQl/base/semester';
import { eclassData } from 'src/app/graphQl/base/eclass';
import { duitesData } from 'src/app/graphQl/base/butys';
import { BehaviorSubject, Observable } from 'rxjs';
import sha1 from 'sha1-file-web';

@Injectable({
    providedIn: 'root'
})
export class BaseService {
    colorItem$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    constructor(private business: BusinessService,
        private gragpl: GraphqlService) {
    }

    // 年级数据
    getGradeData() {
        const parmas = {
            query: gradesNewList,
            variables: {
                page: 1,
                limit: 1000,
                condition: {}
            }
        };
        this.gragpl.GQuery(parmas).subscribe((res: any) => {
            if (res.data.gradesNew.code === 200) {
                this.business.setItem(GRADE, res.data.gradesNew.data);
            }
        });
    }

    // 学科数据
    getSubjectData() {
        const parmas = {
            query: subjectNewLists,
            variables: {
                page: 1,
                limit: 1000,
                condition: {}
            }
        };
        this.gragpl.GQuery(parmas).subscribe((res: any) => {
            if (res.data.subjectsNew.code === 200) {
                const data = res.data.subjectsNew.data;
                this.business.setItem(SUBJECT, data);
            }
        });
    }

    // 学段数据
    getPeriodData() {
        const parmas = {
            query: schoolPeriods,
            variables: {
                page: 1,
                limit: 1000,
                condition: {}
            }
        };
        this.gragpl.GQuery(parmas).subscribe((res: any) => {
            if (res.data.schoolPeriods.code === 200) {
                this.business.setItem(PERIODS, res.data.schoolPeriods.data);
            }
        });
    }

    // 获取教师信息 condition里传条件
    getTeacherData(condition: any, cb) {
        const parmas = {
            query: newTeachers,
            variables: {
                page: 0,
                limit: 0,
                condition
            }
        };
        this.gragpl.GQuery(parmas).subscribe((res: any) => {
            cb(res);
        });
    }

    // 获取学期信息 page分页，limit每个页面显示数量，conditon筛选条件
    getSemesterData(page: number, limit: number, condition: any, cb) {
        const params = {
            query: semesterData,
            variables: {
                page,
                limit,
                condition
            }
        };
        this.gragpl.GQuery(params).subscribe((res: any) => {
            cb(res);
        });
    }

    // 获取班级信息 page分页，limit每个页面显示数量，conditon筛选条件
    getEclassData(page: number, limit: number, condition: any, cb) {
        const params = {
            query: eclassData,
            variables: {
                page,
                limit,
                condition
            }
        };
        this.gragpl.GQuery(params).subscribe((res: any) => {
            cb(res);
        });
    }

    // 获取职务信息 page分页，limit每个页面显示数量，conditon筛选条件
    getDuitesData(page: number, limit: number, condition: any, cb) {
        const params = {
            query: duitesData,
            variables: {
                page,
                limit,
                condition
            }
        };
        this.gragpl.GQuery(params).subscribe((res: any) => {
            cb(res);
        });
    }

    getHash(file: any): Observable<any> {
        return new Observable(observa => {
            sha1(file).then(res => {
                observa.next(res);
            });
        });
    }

    mGetData() {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const d = new Date(year, month, 0);
        return d.getDate();
    }

    // 获取当月日期
    getGenerateData() {
        const now = new Date();
        const current_month_num = this.mGetData();
        const current_month = [];
        for (let i = 0; i < current_month_num; i++) {
            const day = now.setDate(i);
            const everyDay = this.formatDate(day);
            const dateDay = new Date(everyDay);
            current_month.push(dateDay);
        }
        return current_month;
    }

    // 标准时间格式改为 2020-09-02
    formatDate(date: any): string {
        date = new Date(date);
        const myyear = date.getFullYear();
        let mymouth = date.getMonth() + 1;
        let myweekday = date.getDate();
        mymouth < 10 ? mymouth = '0' + mymouth : mymouth;
        myweekday > 10 ? myweekday = '0' + myweekday : myweekday;
        return `${myyear}-${mymouth}-${myweekday}`;
    }
    //时间戳转2020-10-10 02:10:22
    timestampToTime(timestamp) {
        var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = date.getDate() + ' ';
        let h = date.getHours() + ':';
        let m = date.getMinutes() + ':';
        let s = date.getSeconds();
        return Y + M + D + h + m + s;
    }

    // 获取时间 'HH:mm:ss'
    getFormatDataTime(date: Date): string {
        return formatDate(date, 'HH:mm:ss', 'zh-Hans');
    }

    // 获取日期和时间一起 'yyyy-MM-dd HH:mm:ss'
    getFormatDateTimeFull(date: Date): string {
        return formatDate(date, 'yyyy-MM-dd HH:mm:ss', 'zh-Hans');
    }

    // 获取日期 yyyy-MM-dd
    getFormatDate(date: Date): string {
        return formatDate(date, 'yyyy-MM-dd', 'zh-Hans');
    }

    // HH:mm:ss
    getDateFromStringTime(date: string): Date {
        const a = Number.parseInt(date.slice(0, 2));
        const b = Number.parseInt(date.slice(3, 5));
        const c = Number.parseInt(date.slice(6, 8));
        return new Date(0, 0, 0, a, b, c);
    }

    // Date类型的年月日，标准格式
    getDateFromsStringDate(date: string): Date {
        const a = Number.parseInt(date.slice(0, 4));
        const b = Number.parseInt(date.slice(5, 7)) - 1;
        const c = Number.parseInt(date.slice(8, 10));
        return new Date(a, b, c, 0, 0, 0);
    }

    // yyyy-MM-dd
    getFirstDay(date: Date): string {
        return this.getFormatDate(new Date(date.getFullYear(), date.getMonth(), 1));
    }

    // 	查询数据字典
    getDicType(week, cd) {
        const params = {
            query: dictionaysList,
            variables: {
                dictionary_code: week
            }
        };
        this.gragpl.GQuery(params).subscribe((res: any) => {
            cd(res);
        });
    }
}
