module.exports = {
    // 资源首页
    "resources_home": {
        "recommend": "ᠰᠠᠨᠠᠯ ᠪᠤᠯᠭᠠᠬᠤ", //推荐
        "hot": "ᠬᠠᠯᠠᠮᠰᠢᠯ", //热门
        "newest": "ᠬᠠᠮᠤᠭ ᠰᠢᠨ᠎ᠡ", //最新
        "search": "ᠬᠠᠶᠢᠬᠤ", //搜索条件
        "resources_type": "ᠠᠩᠭᠢᠯᠠᠯ", //分类
        "subject": "ᠬᠢᠴᠢᠶᠡᠯ ᠳᠦᠷᠦᠯ", //科目
        "grade": "ᠣᠨ", //年级
        "like": "ᠲᠠᠭᠠᠰᠢᠶᠠᠬᠤ", //点赞
        "collection": "ᠬᠠᠳᠠᠭᠠᠯᠠᠭᠰᠠᠨ", //收藏
        "download": "ᠪᠠᠭᠤᠯᠭᠠᠭᠰᠠᠨ", //下载
        "like_success": "ᠠᠮᠵᠢᠯᠳᠠ  ᠲᠠᠢ ᠲᠠᠭᠠᠰᠢᠶᠠᠵᠠᠢ ︕", //点赞成功
        "collection_success": "ᠠᠮᠵᠢᠯᠳᠠ  ᠲᠠᠢ ᠬᠠᠳᠠᠭᠠᠯᠠᠵᠠᠢ ︕", //收藏成功
        "download_success": "ᠠᠮᠵᠢᠯᠳᠠ  ᠲᠠᠢ ᠪᠠᠭᠤᠯᠭᠠᠵᠠᠢ ︕", //下载成功

    },
    //教师空间
    "resources_files": {
        "serial_number": "ᠳ᠋ᠤᠭᠠᠷᠯᠠᠯ", //序号
        "file_name": "ᠹᠠᠢᠯ ᠤᠨ ᠨᠡᠷ᠎ᠡ", //文件名
        "file_type": "ᠳᠦᠷᠦᠯ", //类型
        "is_view": "ᠣᠯᠠᠨ ᠳᠦ ᠦᠵᠡᠭᠦᠯᠬᠦ ᠡᠰᠡᠬᠦ ",//大家是否可见
        "file_size": "ᠶᠡᠭᠡ ᠪᠠᠭ᠎ᠠ", //大小
        "file_time": "ᠴᠠᠭ ᠬᠤᠭᠤᠴᠠᠭ᠎ᠠ", //时间
        "file_state": "ᠪᠠᠢᠳᠠᠯ", //状态
        "file_fraction": "ᠬᠤᠪᠢ", //分数
        "file_operation": "ᠠᠵᠢᠯᠯᠠᠬᠤ", //操作
        "audit_status": "ᠬᠢᠨᠠᠯᠳᠠ ᠶᠢᠨ ᠪᠠᠢᠳᠠᠯ",  //审核状态
        "my_ranking": "ᠮᠢᠨᠤ ᠨᠡᠷ᠎ᠡ ᠶᠢᠨ ᠵᠢᠭᠰᠠᠭᠠᠯᠳᠠ",  //我的排名
        "available_integral": "ᠬᠡᠷᠡᠭᠯᠡᠵᠦ ᠪᠤᠯᠬᠤ ᠤᠨᠤᠭ᠎ᠠ",  //可用积分
        "my_points": "ᠮᠢᠨᠤ ᠤᠨᠤᠭ᠎ᠠ",  //我的积分
        "upload": "ᠠᠴᠢᠶᠠᠯᠠᠬᠤ", //上传
        "create": "ᠪᠠᠶᠢᠭᠤᠯᠬᠤ", //创建
        "download": "ᠪᠠᠭᠤᠯᠭᠠᠬᠤ", //下载
        "files_name": "ᠮᠠᠲ᠋ᠧᠷᠢᠶᠠᠯ ᠤᠨ ᠨᠡᠷ᠎ᠡ", //文件名
        "large_small": "ᠶᠡᠬᠡ ᠪᠠᠭ᠎ᠠ", //大小
        "upload_time": "ᠠᠴᠢᠶᠠᠯᠠᠭᠰᠠᠨ ᠴᠠᠭ", //上传时间
        "all_files": "ᠪᠦᠬᠦ ᠮᠠᠲ᠋ᠧᠷᠢᠶᠠᠯ", //全部文件
        "delete": "ᠬᠠᠰᠤᠬᠤ", //删除
        "edit": "ᠵᠠᠰᠠᠬᠤ", //编辑
        "rename": "ᠳᠠᠬᠢᠨ ᠨᠡᠷᠡᠯᠡᠬᠦ", //重命名
        "move": "ᠰᠢᠯᠵᠢᠭᠦᠯᠬᠦ", //移动
        "copy_to": "ᠬᠠᠭᠤᠯᠬᠤ", //复制到
        "preservation": "ᠬᠠᠳᠠᠭᠠᠯᠠᠬᠤ", //保存
        "introduce": "ᠲᠠᠨᠢᠯᠴᠠᠭᠤᠯᠭ᠎ᠠ", //介绍
        "comment": "ᠰᠡᠳᠬᠢᠭᠳᠡᠯ", //评论
        "content_list": "ᠠᠭᠤᠯᠭ᠎ᠠ",//内容
        "comment_content": "ᠰᠡᠳᠬᠢᠭᠳᠡᠯ  ᠦᠨ  ᠠᠭᠤᠯᠭ᠎ᠠ ", //评论内容;
        "common": "ᠨᠠᠶᠢᠳᠠ ", //共
        "comment_to": " ᠰᠡᠳᠬᠢᠭᠳᠡᠯ ᠲᠡᠢ", //评论
        "share": "ᠬᠤᠪᠢᠶᠠᠯᠴᠠᠬᠤ", //分享
        "share_file": "ᠬᠤᠪᠢᠶᠠᠯᠴᠠᠭᠰᠠᠨ", //分享的文件
        "period": "ᠰᠤᠷᠤᠯᠭ᠎ᠠ ᠶᠢᠨ ᠱᠠᠲᠤ", //学段
        "grade": "ᠣᠨ", //年级
        "subject": "ᠬᠢᠴᠢᠶᠠᠯ ᠲᠥᠷᠥᠯ", //学科
        "textbook": "ᠵᠢᠭᠠᠬᠤ ᠮᠠᠲ᠋ᠧᠷᠢᠶᠠᠯ", //课本
        "knowledge": "ᠮᠡᠳᠡᠯᠭᠡ  ᠶᠢᠨ ᠴᠡᠭ", //知识点
        "chapter": "ᠪᠦᠯᠦᠭ", //章节
        "tabs": "ᠱᠣᠰᠢᠭ᠎ᠠ", //标签
        "tag_type": "ᠲᠥᠷᠥᠯ ᠵᠦᠢᠯ", //类型
        "leaning_success": "ᠰᠤᠷᠤᠯᠴᠠᠯᠭ᠎ᠠ ᠳᠤ ᠠᠮᠵᠢᠯᠳᠠ  ᠲᠠᠢ ᠤᠷᠤᠵᠠᠢ ︕", //加入学习成功
        "select_chapter": "ᠬᠡᠰᠡᠭ ᠪᠦᠯᠦᠭ ᠢᠶᠠᠨ ᠰᠤᠩᠭᠤᠬᠤ", //选择章节
        "select_knowledge_point": "ᠮᠡᠳᠡᠯᠭᠡ ᠶᠢᠨ ᠴᠡᠭ ᠢᠶᠡᠨ ᠰᠤᠩᠭᠤᠬᠤ", //选择知识点
        "select_subject": "ᠬᠢᠴᠢᠶᠡᠯ ᠲᠥᠷᠥᠯ ᠢᠶᠡᠨ ᠰᠣᠩᠭᠣᠬᠤ", //请选择学科
        "select_grade": "ᠣᠨ ᠢᠶᠡᠨ ᠰᠣᠩᠭᠣᠬᠤ ", //请选择年级
        "select_period": "ᠰᠤᠷᠤᠯᠭ᠎ᠠ ᠶᠢᠨ ᠬᠤᠭᠤᠴᠠᠭ᠎ᠠ ᠪᠠᠨ ᠰᠤᠩᠭᠤᠬᠤ", //选择学段
        "select_textbook": "ᠵᠢᠭᠠᠬᠤ ᠮᠠᠲ᠋ᠧᠷᠢᠶᠠᠯ ᠢᠶᠡᠨ ᠰᠣᠩᠭᠣᠬᠤ", //选择课本
        "select_textbooks": "ᠵᠢᠭᠠᠬᠤ ᠮᠠᠲ᠋ᠧᠷᠢᠶᠠᠯ ᠢᠶᠡᠨ ᠰᠣᠩᠭᠣᠭᠠᠷᠠᠶ", //请选择课本
        "select_tabs": "ᠱᠣᠰᠢᠭ᠎ᠠ ᠦᠨ ᠰᠣᠩᠭᠣᠬᠤ", //选择标签
        "file_preview": "ᠪᠢᠴᠢᠭ ᠮᠠᠲ᠋ᠧᠷᠢᠶᠠᠯ ᠢ ᠤᠷᠢᠳᠴᠢᠯᠠᠨ ᠦᠵᠡᠬᠦ", //文件预览
        "copy_success": "ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠬᠠᠭᠤᠯᠬᠠᠰᠡᠨ", //复制成功
        "move_success": "ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠰᠢᠯᠵᠢᠭᠦᠯᠦᠭᠰᠡᠨ", //移动成功
        "status_success": "ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠬᠢᠨᠠᠭᠰᠠᠨ", //移动成功
        "delete_success": "ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠬᠠᠰᠤᠭᠰᠠᠨ", //删除成功
        "return_parent": "ᠳᠡᠭᠡᠷ᠎ᠡ ᠨᠢᠭᠡ ᠱᠠᠲᠤᠨ ᠳᠠᠭᠠᠨ ᠪᠤᠴᠠᠬᠤ", //返回上一级
        "new_folder": "ᠪᠠᠢᠭᠤᠯᠬᠤ", //新建文件夹
        "list_strip": " ᠵᠤᠷᠪᠤᠰ", //条
        "select_all": "ᠪᠦᠬᠦᠨ ᠢ ᠰᠣᠩᠭᠣᠬᠤ", //全部选择
        "files_cant_be_shared": "ᠴᠤᠮᠤᠭ ᠶᠢ ᠬᠤᠪᠢᠶᠠᠯᠴᠠᠬᠤ ᠪᠤᠯᠣᠮᠵᠶ ᠦᠭᠡᠢ", //文件夹分享不了
        "file_not_preview": "ᠤᠷᠢᠳᠴᠢᠯᠠᠨ ᠦᠵᠡᠬᠦ ᠶᠢ ᠤᠳᠤᠬᠠᠨ ᠳᠤ ᠳᠡᠮᠵᠢᠬᠦ ᠦᠬᠡᠢ", //暂时不支持预览
        "shared_cannot_be_deleted": "ᠨᠢᠭᠡᠨᠲᠡ ᠬᠤᠪᠢᠶᠠᠯᠴᠠᠭᠰᠠᠨ ᠪᠣᠯ ᠬᠠᠰᠤᠵᠤ ᠪᠣᠯᠬᠤ ᠦᠭᠡᠢ", //已分享不能删除
        "the_delete_data": "ᠲᠤᠰ ᠵᠣᠷᠪᠣᠰ ᠢ ᠬᠠᠰᠤᠬᠤ ᠦᠦ ？", //确定要删除这条数据
        "reset": "ᠲᠠᠬᠢᠨ ᠲᠤᠬᠢᠷᠠᠭᠤᠯᠬᠤ", //重置
        "recycle_bin": "ᠬᠣᠭ ᠤᠨ ᠰᠠᠪᠠ", //回收站
        "reduction": "ᠪᠤᠴᠠᠭᠠᠬᠤ", //还原
        "delete_time": "ᠬᠠᠰᠤᠭᠰᠠᠨ ᠴᠠᠭ", //删除时间
        "examine_edit": "ᠬᠢᠨᠠᠯᠲᠠ  ᠶᠢ ᠵᠠᠰᠠᠬᠤ", //修改审核
        "delete_sared": "ᠬᠤᠪᠢᠶᠠᠯᠴᠠᠬᠤ ᠪᠡᠨ ᠪᠠᠢᠬᠤ", //取消分享
        "delete_sared_success": "ᠬᠤᠪᠢᠶᠠᠯᠴᠠᠬᠤ ᠪᠡᠨ ᠪᠠᠶᠢᠭᠰᠠᠨ", //取消分享
        "has_the_same_file_name": "ᠠᠳᠠᠯᠢ ᠨᠡᠷ᠎ᠡ ᠲᠠᠢ ᠹᠠᠶᠢᠯ ᠪᠠᠶᠢᠨ᠎ᠠ ᠂ ᠳᠠᠬᠢᠨ ᠨᠡᠷᠡᠯᠡᠭᠡᠷᠡᠢ！", //有相同的文件名，请从新输入
        "file_has_been_uploaded": "ᠲᠤᠰ ᠹᠠᠶᠢᠯ ᠨᠢᠭᠡᠨᠲᠡ ᠣᠷᠣᠰᠢᠪᠠ ᠂ ᠪᠤᠰᠤᠳ ᠹᠠᠶᠢᠯ  ᠢ ᠰᠣᠩᠭᠣᠭᠠᠷᠠᠢ ！", //此文件已上传，请选择别的文件
        "folder": "ᠴᠣᠮᠣᠭ",//文件夹
        "annex": "ᠳᠠᠭᠠᠯᠳᠤᠭᠤᠯᠤᠯ ", //参考资料
    },
    //我的收藏
    "resources_info": {
        "my_like": "ᠳᠠᠭᠠᠰᠢᠶᠠᠭᠰᠠᠨ", //我的点赞
        "my_collection": "ᠬᠠᠳᠠᠭᠠᠯᠠᠭᠰᠠᠨ", //我的收藏
        "my_download": "ᠪᠠᠭᠤᠯᠭᠠᠭᠰᠠᠨ", //我的下载
    },
    //资源管理
    "resources_management": {
        "article_title": "ᠵᠤᠬᠢᠶᠠᠯ ᠤᠨ ᠨᠡᠷᠡᠢᠳᠦᠯ", //文章名称
        "uploaded_by": "ᠠᠴᠢᠶᠠᠯᠠᠭᠰᠠᠨ ᠬᠦᠮᠦᠨ", //上传人
        "upload_time": "ᠠᠴᠢᠶᠠᠯᠠᠭᠰᠠᠨ ᠴᠠᠭ", //上传时间
        "not_reviewed": "ᠬᠢᠨᠠᠭ᠎ᠠ ᠦᠭᠡᠢ", //未审核
        "reviewed": "ᠬᠢᠨᠠᠬᠤ", //未审核
        "not_recommended": "ᠲᠠᠨᠢᠯᠴᠠᠭᠤᠯᠬᠤ", //未推荐
        "examine_status": "ᠬᠢᠨᠠᠯᠲᠠ ᠶᠢᠨ ᠪᠠᠶᠢᠳᠠᠯ", //审核状态
        "examine_yes": "ᠵᠦᠪᠰᠢᠶᠡᠷᠡᠨ᠎ᠡ", //已通过
        "examine_nopass": "ᠵᠥᠪᠰᠢᠶᠡᠷᠡᠬᠦ ᠦᠭᠡᠢ", //未通过
        "leave_reason": "ᠬᠢᠨᠠᠯᠲᠠ ᠶᠢᠨ ᠰᠠᠨᠠᠭᠤᠯᠭ᠎ᠠ ", //审核意见
        "select_leave_reason": "ᠬᠢᠨᠠᠯᠲᠠ ᠶᠢᠨ ᠰᠠᠨᠠᠭᠤᠯᠭ᠎ᠠ ᠪᠠᠨ ᠪᠢᠴᠢᠭᠡᠷᠡᠢ ", //请输入审核意见
    },
    //基本配置
    "resources_score_config": {
        "configuration_item": "ᠳᠡᠭᠡᠷ᠎ᠡ ᠲᠠᠯ᠎ᠠ ᠳᠤ ᠲᠤᠬᠢᠷᠠᠭᠤᠯᠬᠤ", //	配置项
        "return_rate": "ᠪᠡᠯᠡᠨ ᠮᠥᠩᠬᠦ ᠪᠤᠴᠠᠭᠠᠵᠤ ᠠᠪᠬᠤ ᠨᠤᠷᠮ᠎ᠠ", //返现率
    },
    //积分排行
    "resources_score_list": {
        "list_ranking": "ᠳ᠋ᠤᠭᠠᠷᠯᠠᠯ", //排名
        "full_name": "ᠨᠡᠷ᠎ᠡ", //姓名
        "upload_master_file": "ᠠᠴᠢᠶᠠᠯᠠᠭᠰᠠᠨ ᠨᠡᠢᠳᠡ ᠹᠠᠢᠯ", //上传总文件
        "picture": "ᠵᠢᠷᠤᠭ", //图片
        "video": "ᠸᠢᠳᠢᠤ᠋", //视频
        "audio": "ᠳᠠᠭᠤ", //音频
        "document": "DOC", //文档
        "other": "ᠪᠤᠰᠤᠳ", //其他
        "file": "ᠹᠠᠶᠢᠯ",//文件
        "my_lesson": "ᠬᠢᠴᠢᠶᠡᠯ  ᠦᠨ ᠪᠡᠯᠡᠳᠬᠡᠮᠵᠢ",//备课
        "integral": "ᠣᠨᠣᠭ᠎ᠠ", //	积分
        "enter_name": "ᠨᠡᠷ᠎ᠡ ᠪᠡᠨ ᠪᠢᠴᠢᠭᠡᠷᠡᠢ", //请输入姓名
        "all_file": "ᠪᠦᠬᠦᠳᠡ", //全部
        "course_content": "ᠬᠢᠴᠢᠶᠡᠯ", //单课程内容
        "cloud_content": 'ᠬᠢᠴᠢᠶᠡᠯᠯᠡᠭᠡ',//课程内容
        "text": "ᠦᠰᠦᠭ ᠣᠷᠣᠭᠤᠯᠬᠤ", //文本
        "test_question": "ᠰᠡᠳᠦᠪ",//题库
        "teaching_design": "ᠵᠢᠭᠠᠬᠤ ᠰᠤᠷᠬᠤ ᠳᠦᠰᠦᠪ",//教学设计
        "examination_system": "ᠰᠢᠯᠭᠠᠯᠲᠠ ᠭᠠᠷᠭᠠᠬᠤ ᠰᠢᠰᠲ᠋ᠧᠮ",//考试系统
    },
};
