
import gql from 'graphql-tag';

/**
 * 查询学期列表数据
 * @param page 分页
 * @param limit 每页数据量
 * @param  查询分类
 */
const semesterData = gql`
	query (
        $page: Int!,
        $limit: Int!,
        $condition: SemesterCondition,
      
    ){
		Semesters(page: $page, limit: $limit, condition:$condition){
            code
            msg
            data {
              id
              semester_code
              name{
                zh
                en
                mn
              }
              yearly_code
              is_current
              start_date
              end_date
              week_total
              teaching_start_date
              teaching_end_date
              semester_status
              first_week_monday
              first_week_sunday
              zujuanCode
            }
            total
        }
	}
`;

export {
  semesterData
}