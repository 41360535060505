import gql from 'graphql-tag';
const subjectNewLists = gql`
	query ($page: Int, $limit: Int){
		subjectsNew (page: $page, limit: $limit){
			code
            msg
            total
            data {
                id
                type{
                    name{
                        zh
                    }
                }
                subject_name {
                    en
                    mn
                    zh
                }
                mark
                subject_slug
                subject_number
                period {
                    id
                    name {
                        en
                        mn
                        zh
                    }
                }
                grade {
                    id
                    name {
                        en
                        mn
                        zh
                    }
                }
            }
		}
	}
`
const findSubjectData = gql`
    query($id: Int!){
        subject (id: $id) {
            id
            subject_name {
                en
                mn
                zh
            }
            subject_slug
            subject_order
            subject_number
            mark
            type{
                id
                name{
                    zh
                }
                dictionary_value
            }
            period {
                id
            }
            grade {
                id
            }
        }
    }
`
export {
    subjectNewLists,
    findSubjectData
}