module.exports = {
    "my_work": {
        "interviewed": "ᠰᠤᠷᠪᠤᠯᠵᠢᠯᠠᠭᠳᠠᠬᠤ",//被访问
        "cloud_classroom": "ᠬᠢᠴᠢᠶᠡᠯᠯᠡᠭᠡ", //云课堂
        "free_class": "ᠬᠢᠴᠢᠶᠡᠯ", //自由课
        "class": "ᠬᠢᠴᠢᠶᠡᠯ",//单课
        "cloud_class": "ᠬᠢᠴᠢᠶᠡᠯᠯᠡᠭᠡ",//云课堂
        "teaching_resource": "ᠰᠤᠷᠭᠠᠯᠲᠠ ᠰᠤᠷᠪᠤᠯᠵᠢ",//教学资源
        "teacher": "ᠪᠠᠭᠰᠢ",//教师
        "special_column": "ᠲᠤᠰᠬᠠᠢ ᠪᠤᠯᠤᠩ",// 专栏
        "paper": "ᠰᠢᠯᠭᠠᠯᠲᠠ", //试卷
        "paper_collect": "ᠰᠢᠯᠭᠠᠯᠲᠠ ᠶᠢ ᠬᠠᠳᠠᠭᠠᠯᠠᠭᠰᠠᠨ", //试卷收藏
        "view_test_paper": "ᠰᠢᠯᠭᠠᠯᠲᠠ ᠶᠢ ᠦᠵᠡᠭᠰᠡᠨ", //查看试卷
        "paper_download": "ᠰᠢᠯᠭᠠᠯᠳᠠ ᠪᠠᠭᠤᠯᠭᠠᠭᠰᠠᠨ", //试卷下载
        "paper_like": "ᠰᠢᠯᠭᠠᠯᠲᠠ ᠶᠢ ᠲᠠᠭᠠᠰᠢᠶᠠᠭᠰᠠᠨ", //试卷点赞
        "collected_papers": "ᠬᠠᠳᠠᠭᠠᠯᠠᠭᠳᠠᠭᠰᠠᠨ ᠰᠢᠯᠭᠠᠯᠲᠠ", //被收藏的试卷
        "collected_question": "ᠬᠠᠳᠠᠭᠠᠯᠠᠭᠳᠠᠭᠰᠠᠨ ᠰᠡᠳᠦᠪ", //被收藏的题库
        "cited_question": "ᠰᠢᠯᠭᠠᠯᠲᠠ ᠳᠤ ᠬᠡᠷᠡᠭᠯᠡᠭᠰᠡᠨ ᠰᠡᠳᠦᠪ", //被使用的题
        "paper_question": "ᠰᠡᠳᠦᠪ ᠰᠢᠯᠭᠠᠯᠲᠠ ᠶᠢᠨ ᠬᠥᠮᠥᠷᠭᠡ", //试卷题库
        "works": "ᠡᠬᠢ ᠮᠠᠲ᠋ᠧᠷᠢᠶᠠᠯ",//资源
        "resources_group": "ᠪᠣᠷᠣ ᠮᠠᠲ᠋ᠧᠷᠢᠶᠠᠯ",//素材库
        "word": "ᠹᠠᠢᠯ", //文档
        "question": "ᠰᠡᠳᠦᠪ", //题库
        "question_collect": "ᠰᠡᠳᠦᠪ ᠢ ᠬᠠᠳᠠᠭᠠᠯᠠᠭᠰᠠᠨ", //题库收藏
        "class_comment": "ᠬᠢᠴᠢᠶᠡᠯ ᠦᠨ ᠦᠨᠡᠯᠡᠯᠳᠡ ᠶᠢᠨ ᠲᠤᠭ᠎ᠠ",//我的单课评论数量
        "view_test_question": "ᠰᠡᠳᠦᠪ ᠢ ᠦᠵᠡᠭᠰᠡᠨ", //查看题库
        "question_like": "ᠰᠡᠳᠦᠪ ᠢ ᠲᠠᠭᠠᠰᠢᠶᠠᠭᠰᠠᠨ", //题库点赞
        "lesson": "ᠬᠢᠴᠢᠶᠡᠯ ᠦᠨ ᠪᠡᠯᠡᠳᠬᠡᠮᠵᠢ ", //备课
        "network": "ᠰᠦᠯᠵᠢᠶᠡᠨ ᠲᠠᠪᠠᠭ",//网盘
        "number": "ᠲᠣᠭ᠎ᠠ",//数量
        "focus_sucess": "ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠠᠩᠬᠠᠷᠤᠭᠰᠠᠨ",//关注成功
        "my_fans": "ᠠᠡᠭᠬᠠᠷᠤᠭᠰᠠᠳ",//我的粉丝
        "my_follow": "ᠠᠡᠭᠬᠠᠷᠤᠭᠰᠠᠨ",//我的关注
        "resources": "ᠰᠤᠷᠪᠤᠯᠵᠢ ᠶᠢᠨ  ᠬᠥᠮᠥᠷᠭᠡ", // 资源库
        "all_download": "ᠪᠥᠭᠥᠳᠡ ᠪᠠᠭᠤᠯᠭᠠᠭᠰᠠᠨ",//全部下载
        "all_collection": "ᠪᠥᠭᠥᠳᠡ ᠬᠠᠳᠠᠭᠠᠯᠠᠭᠰᠠᠨ", //全部收藏
        "all_like": "ᠪᠥᠭᠥᠳᠡ ᠲᠠᠭᠠᠰᠢᠶᠠᠭᠰᠠᠨ",//全部点赞
        "all_visit": "ᠪᠥᠭᠥᠳᠡ  ᠠᠢᠯᠴᠢᠯᠠᠭᠰᠠᠨ",//全部访问
        "most_download": "ᠬᠠᠮᠤᠭ ᠤᠨ ᠠᠷᠪᠢᠨ ᠪᠠᠭᠤᠯᠭᠠᠭᠰᠠᠨ",//最多下载
        "most_collection": "ᠬᠠᠮᠤᠭ ᠤᠨ ᠠᠷᠪᠢᠨ ᠬᠠᠳᠠᠭᠠᠯᠠᠭᠰᠠᠨ", //最多收藏
        "examine": "ᠬᠢᠨᠠᠯᠲᠠ",//审核
        "commit": "ᠦᠨᠡᠯᠡᠯᠲᠡ",//评论
        "to_examine": "ᠬᠢᠨᠠᠬᠤ",//审核
        "notes": "ᠲᠡᠮᠳᠡᠭᠯᠡᠯ",//笔记
        "examine_success": "ᠬᠢᠨᠠᠭᠰᠠᠨ",//已审核
        "all_examine": "ᠪᠥᠭᠥᠳᠡ ᠬᠢᠨᠠᠭᠰᠠᠨ",//全部审核
        "my_note": "ᠲᠡᠮᠳᠡᠭᠯᠡᠯ",//我的笔记
        "my_liked_notes": "ᠲᠡᠮᠳᠡᠭᠯᠡᠯ ᠢ ᠲᠠᠭᠠᠰᠢᠶᠠᠭᠰᠠᠨ",//我被点赞的笔记
        "my_commited_notes": "ᠲᠡᠮᠳᠡᠭᠯᠡᠯ ᠢ ᠦᠨᠡᠯᠡᠭᠰᠡᠨ",//我被评论的笔记
        "my_collected_notes": "ᠲᠡᠮᠳᠡᠭᠯᠡᠯ ᠢ ᠬᠠᠳᠠᠭᠠᠯᠠᠭᠰᠠᠨ",//我被收藏的笔记
        "my_class_notes": "ᠬᠢᠴᠢᠶᠡᠯᠯᠡᠭᠡ ᠶᠢᠨ ᠲᠡᠮᠳᠡᠭᠯᠡᠯ",//我课堂笔记
        "my_collect_notes": "ᠬᠠᠳᠠᠭᠠᠯᠠᠭᠰᠠᠨ  ᠲᠡᠮᠳᠡᠭᠯᠡᠯ",//我收藏的笔记
        "my_like_notes": "ᠲᠠᠭᠠᠰᠢᠶᠠᠭᠰᠠᠨ  ᠲᠡᠮᠳᠡᠭᠯᠡᠯ",//我点赞的笔记
        "my_commit_notes": "ᠦᠨᠡᠯᠡᠭᠰᠡᠨ ᠲᠡᠮᠳᠡᠭᠯᠡᠯ",//我评论的笔记
        "my_commit": "ᠦᠨᠡᠯᠡᠭᠰᠡᠨ",//我评论
        "my_commited": "ᠦᠨᠡᠯᠡᠭᠳᠡᠭᠰᠡᠨ",//被评论的
        "reply_to_comments": "ᠦᠨᠡᠯᠡᠯᠲᠡ ᠶᠢ ᠬᠠᠷᠢᠭᠤᠯᠬᠤ",//回复评论
        "published": "ᠨᠡᠢᠲᠡᠯᠡᠭᠰᠡᠨ",//已发布
        "under_review": "ᠬᠢᠨᠠᠯᠲᠠ ᠳᠤ",//审核中
        "approved": "ᠥᠩᠭᠡᠷᠡᠭᠰᠡᠨ", //审核通过
        "audit_failed": "ᠥᠩᠭᠡᠷᠡᠭᠰᠡᠨ ᠦᠭᠡᠢ", //审核未通过
        "learning": "ᠦᠷᠭᠦᠯᠵᠢᠯᠡᠭᠰᠡᠨ",//学习中
        "end_of_study": "ᠳᠠᠭᠤᠰᠤᠭᠰᠠᠨ", //学习结束
        "learning_personal": "ᠰᠤᠷᠤᠯᠴᠠᠭᠰᠠᠨ ᠬᠥᠮᠦᠨ",//学习人数
        "to_collect": "ᠬᠠᠳᠠᠭᠠᠯᠠᠭᠰᠠᠨ",//收藏
        "to_like": "ᠲᠠᠭᠠᠰᠢᠶᠠᠭᠰᠠᠨ",//点赞            
        "to_visit": "ᠠᠢᠯᠴᠢᠯᠠᠭᠰᠠᠨ",//访问
        "most_learned_courses": "ᠬᠠᠮᠤᠭ ᠤᠨ ᠠᠷᠪᠢᠨ ᠰᠤᠷᠤᠯᠴᠠᠭᠰᠠᠨ ᠬᠡᠳᠦᠨ ᠬᠢᠴᠢᠶᠡᠯ",//学习最多的课程
        "draft": "ᠨᠤᠤᠷᠠᠭ",//草稿
        "network_use": "ᠬᠡᠷᠡᠭᠯᠡᠭᠰᠡᠨ",//使用
        "network_not": "ᠦᠯᠡᠳᠡᠪᠦᠷᠢ",//未使用
        "documents_numbers": "ᠹᠠᠶᠢᠯ ᠦᠨ ᠬᠡᠮᠵᠢᠶ᠎ᠡ ",//文件量
        "select_subject": "ᠵᠦᠶᠯ ᠢᠯᠭᠠᠯ ᠢ ᠰᠢᠯᠢᠨ ᠰᠣᠩᠭᠣᠬᠤ",//选择学科
        "school_slogan": "ᠪᠦᠲᠦᠮᠵᠢᠲᠡᠢ ᠬᠢᠴᠢᠶᠡᠯᠯᠡᠭᠡ ᠴᠢᠨᠠᠷᠲᠠᠢ ᠳᠠᠪᠲᠠᠯᠭ᠎ᠠ ",//
    },
    "my_learning": {
        "my_share": "ᠬᠤᠪᠢᠶᠠᠯᠴᠠᠭᠰᠠᠨ",//我的分享
        "my_recent_download": "ᠣᠷᠴᠢᠮ ᠤᠨ ᠪᠠᠭᠤᠯᠭᠠᠭᠰᠠᠨ",//我的最近下载
        "my_recent_collect": "ᠣᠷᠴᠢᠮ ᠤᠨ ᠬᠠᠳᠠᠭᠠᠯᠠᠭᠰᠠᠨ",//我的最近收藏
        "my_recent_commit": "ᠣᠷᠴᠢᠮ ᠤᠨ ᠦᠨᠡᠯᠡᠭᠰᠡᠨ ᠹᠠᠢᠯ",//我的最近评论
        "learning_class": "ᠰᠤᠷᠤᠯᠴᠠᠭᠰᠠᠨ ᠬᠢᠴᠢᠶᠡᠯ",//学习的课程
        "learning_time": "ᠰᠤᠷᠤᠯᠴᠠᠭᠰᠠᠨ  ᠴᠠᠭ",//学习时间
        "my_test_paper": "ᠤᠭᠰᠠᠷᠠᠭᠰᠠᠨ ᠰᠢᠯᠭᠠᠯᠲᠠ",//我的组卷
        "my_download_paper": "ᠪᠠᠭᠤᠯᠭᠠᠭᠰᠠᠨ ᠰᠢᠯᠭᠠᠯᠲᠠ", //我下载的试卷
        "questions_i_use": "ᠬᠡᠷᠡᠭᠯᠡᠭᠰᠡᠨ ᠰᠡᠳᠦᠪ", //我使用的题库
        "my_examination": "ᠪᠠᠢᠴᠠᠭᠠᠯᠲᠠ", //我的检查
        "error_question": "ᠪᠤᠷᠤᠭᠤ ᠰᠡᠳᠦᠪ", //错误的题
    }
}