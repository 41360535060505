import Setting from '../config';
const formatterLanguage  =(val:any)=>{
    let item:string = val;
    if(typeof val ==='string'){
        item = JSON.parse(val);
    }
    try{
        item = item[Setting.language]
    }
    catch(e){
        item = '数据格式错误'
    }
    return item;
}
export {formatterLanguage}