let resources = require('./resources/index');
let prepare = require('./prepare/index');
let panel = require('./panel/work');
let tags = require('./tags/tags');
let single = require('./single/single');
let teacher = require('./teacher/teacher');
let notice = require('./notice/index')
module.exports = {

    public: {
        editing: 'ᠨᠠᠢᠷᠠᠭᠤᠯᠵᠤ ᠪᠠᠢᠨ᠎ᠠ', // 编辑中
        saving: 'ᠬᠠᠳᠠᠭᠠᠯᠠᠵᠤ ᠪᠠᠶᠢᠨ᠎ᠠ ···', // 正在保存
        saved: 'ᠨᠢᠭᠡᠨᠲᠡ ᠬᠠᠳᠠᠭᠠᠯᠠᠪᠠ', // 已保存
        delete_confirmation: 'ᠬᠠᠰᠤᠬᠤ ᠨᠤᠲᠠᠯᠠᠯ',//删除确认
        sure_delete_data: 'ᠡᠳᠡᠭᠡᠷ ᠲᠣᠭ᠎ᠠ ᠪᠠᠷᠢᠮᠲᠠ  ᠢ ᠪᠦᠷ  ᠬᠠᠰᠤᠨ᠎ᠠ ᠦᠦ ？',//您确定要删除这些数据吗
        all: 'ᠪᠦᠬᠦ', // 全部
        manager: 'ᠬᠠᠮᠢᠶᠠᠷᠤᠭᠴᠢ',//管理员
        requred: 'ᠪᠢᠴᠢᠭᠡᠷᠡᠢ',//请填写
    },
    //登录
    "login": {
        "username": "ᠬᠡᠷᠡᠭᠯᠡᠭᠴᠢ ᠶᠢᠨ ᠨᠡᠷ᠎ᠡ", //用户名
        "password": "ᠨᠢᠭᠤᠴᠠ ᠦᠭᠡ", //    密码
        "checkPassword": "ᠨᠢᠭᠤᠴᠠ ᠦᠬᠡ ᠪᠡᠨ ᠨᠤᠳᠠᠯᠠᠬᠤ",//确认密码
        "idcard": " ᠪᠡᠶ᠎ᠡ ᠶᠢᠨ ᠦᠨᠡᠮᠯᠡᠯ", //身份证
        "email": "ᠢᠮᠧᠯ ᠦᠨ ᠬᠠᠶ᠋ᠢᠭ", //邮箱
        "mobile": "ᠬᠠᠷᠢᠯᠴᠠᠬᠤ ᠨᠣᠮᠧᠷ", //联系方式
        "login_out": "ᠨᠡᠪᠲᠡᠷᠡᠯᠲᠠ ᠠᠴᠠ ᠭᠠᠷᠬᠤ",//退出登录
        "login": "ᠨᠡᠪᠲᠡᠷᠡᠬᠦ",//登录
        "logging_in": "ᠨᠡᠪᠲᠡᠷᠡᠵᠦ ᠪᠠᠶᠢᠨ᠎ᠠ ",//登录中
        "smart_campus": "ᠤᠶᠤᠨᠴᠢ ᠰᠤᠷᠭᠠᠭᠤᠯᠢ ᠶᠢᠨ ᠬᠦᠷᠢᠶᠡᠨ ᠦ ᠳᠠᠪᠴᠠᠩ  ᠳᠦ ᠨᠡᠪᠳᠡᠷᠡᠬᠦ", //登录智慧校园平台
        "username_and_password_error": "ᠬᠡᠷᠡᠭᠯᠡᠭᠴᠢ ᠶᠢᠨ ᠨᠡᠷ᠎ᠡ ᠪᠤᠶᠤ ᠨᠢᠭᠤᠴᠠ ᠦᠭᠡ ᠪᠤᠷᠤᠭᠤ",//用户名或密码错误
        "remember_password": "ᠨᠢᠭᠤᠴᠠ ᠨᠣᠮᠧᠷ ᠢ ᠴᠡᠭᠡᠵᠢᠯᠡᠬᠦ", //记住密码
        "enter_one_user_name": "ᠬᠡᠷᠡᠭᠯᠡᠭᠴᠢ ᠶᠢᠨ ᠨᠡᠷ᠎ᠡ ᠪᠡᠨ ᠪᠢᠴᠢᠭᠡᠷᠡᠢ",//请输入用户
        "enter_one_password": "ᠨᠢᠭᠤᠴᠠ ᠨᠣᠮᠧᠷ ᠢᠶᠠᠨ ᠪᠢᠴᠢᠭᠡᠷᠡᠢ",//请输入密码
        "submit": "ᠲᠤᠰᠢᠶᠠᠬᠤ", //提交
        "add_success": 'ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠨᠡᠮᠡᠵᠠᠢ', //添加成功
        "add_submit": 'ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠲᠤᠰᠢᠶᠠᠵᠠᠢ', //提交成功
        "add_share": 'ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠬᠤᠪᠢᠶᠠᠯᠴᠠᠭᠰᠠᠨ', //分享成功
        "edit_submit": 'ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠵᠠᠰᠠᠵᠠᠢ', //修改成功
        "edit_success": 'ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠵᠠᠰᠠᠵᠠᠢ', //修改成功
        "edit_error": 'ᠵᠠᠰᠠᠵᠤ ᠳᠡᠶᠢᠯᠦᠭᠰᠡᠨ ᠦᠭᠡᠢ',//修改失败
        "delete_success": "ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠬᠠᠰᠤᠭᠰᠠᠨ", //删除成功
        "add_error": 'ᠨᠡᠮᠡᠵᠦ ᠳᠡᠶᠢᠯᠦᠭᠰᠡᠨ ᠦᠭᠡᠢ',//添加失败
        "focus_sucess": "ᠠᠮᠵᠢᠯᠲᠠ ᠲᠠᠢ ᠠᠩᠬᠠᠷᠤᠭᠰᠠᠨ",//关注成功
        "notice": "ᠮᠡᠳᠡᠭᠡ",//通知
        "news": "ᠰᠣᠨᠢᠨ ᠮᠡᠳᠡᠭᠡ",//新闻
        "announcement": "ᠠᠯᠪᠠᠨ ᠵᠠᠷ",//公告
        "weachar_login": "ᠸᠢᠴᠠᠲ ᠊ᠤᠨ ᠰᠢᠷᠪᠢᠬᠦ ᠰᠢᠰᠲ᠋ᠧᠮ ᠵᠢᠠᠷ ᠰᠢᠷᠪᠢᠵᠦ ᠨᠡᠪᠳᠡᠷᠡᠬᠦ",
    },
    //首页
    "home": {
        "language": "ᠬᠡᠯᠡ",//语言
        "mongolian": "ᠮᠣᠩᠭᠣᠯ",//蒙语
        "timetable": "ᠬᠢᠴᠢᠶᠡᠯ  ᠦᠨ ᠬᠦᠰᠦᠨᠦᠭ",//课表
        "copyright": "ᠪᠦᠷᠢᠨ ᠡᠵᠡᠮᠳᠡᠬᠦ ᠡᠷᠭᠡ",//版权所有
        "orhon_company": "ᠮᠡᠷᠭᠡᠵᠢᠯ  ᠦᠨ ᠦᠢᠯᠡᠴᠢᠯᠡᠭᠡ ᠄ᠦᠪᠦᠷ ᠮᠤᠩᠭᠤᠯ ᠤᠨ ᠤᠷᠬᠤᠨ ᠰᠢᠨᠵᠢᠯᠡᠭᠡ ᠮᠡᠷᠬᠡᠵᠢᠯ ᠦᠨ ᠬᠢᠵᠠᠭᠠᠷᠳᠤ ᠺᠤᠮᠫᠠᠨᠢ", //内蒙古奥尔弘有限责任公司
        "message": "ᠮᠡᠳᠡᠭᠳᠡᠯ ", //消息通知
        "my_job": "ᠠᠵᠢᠯᠯᠠᠬᠤᠢ ᠶᠢᠨ ᠦᠷᠦᠭᠡ", //我的工作
        "my_study": "ᠰᠤᠷᠬᠤᠢ ᠶᠢᠨ ᠦᠷᠦᠭᠡ",//我的学习
        "school_homepage": "ᠡᠬᠢ ᠰᠤᠷᠪᠤᠯᠵᠢ ᠶᠢᠨ ᠬᠦᠮᠦᠷᠭᠡ", //学校主页
        "resource_square": "ᠡᠬᠢ ᠰᠤᠷᠪᠤᠯᠵᠢ ᠶᠢᠨ ᠲᠠᠯᠠᠪᠠᠢ", //资源广场
        "test_paper": "ᠰᠢᠯᠭᠠᠯᠲᠠ ᠶᠢᠨ ᠰᠢᠰᠲ᠋ᠧᠮ", //组卷系统
        "hohhot": "ᠬᠦᠬᠡᠬᠤᠳᠠ",
        "login_out": "ᠨᠡᠪᠲᠡᠷᠡᠯᠲᠠ ᠠᠴᠠ ᠭᠠᠷᠬᠤ",//退出登录
        "personal_settings": "ᠬᠤᠪᠢ ᠬᠦᠮᠦᠨ ᠦ ᠲᠤᠬᠢᠷᠠᠭᠤᠯᠭ᠎ᠠ",//个人设置
        "clear_cache": "ᠬᠠᠳᠠᠭᠠᠯᠠᠯᠳᠠ ᠶᠢ ᠴᠡᠪᠡᠷᠯᠡᠬᠦ", // 清除缓存
        "full_screen": "ᠪᠦ᠋ᠷᠢᠨ ᠳᠡᠯᠭᠡᠴᠡ", // 全屏
        "home_page": "ᠲᠡᠷᠢᠭᠦᠨ ᠨᠢᠭᠤᠷ",//首页
        "basic_settings": "ᠦᠨᠳᠦᠰᠦᠨ ᠪᠠᠶᠢᠷᠢᠯᠠᠭᠤᠯᠤᠯᠲᠠ",//基础设置
        "security_setting": "ᠠᠶᠤᠯᠭᠦᠶ ᠪᠠᠶᠢᠷᠢᠯᠠᠭᠤᠯᠤᠯᠲᠠ", //安全设置
        "file_upload": "ᠠᠴᠢᠶᠠᠯᠠᠬᠤ",//简写上传
        "follow": "ᠠᠩᠬᠠᠷᠬᠤ",//关注
        "compiler": "ᠨᠠᠶᠢᠷᠠᠭᠤᠯᠬᠤ",//编辑
        "delete": "ᠬᠠᠰᠤᠬᠤ",//删除
        "release": "ᠨᠡᠶᠢᠲᠡᠯᠡᠬᠦ",//发布
        "index_monday": "ᠭᠠᠷᠠᠭ ᠦᠨ ᠨᠢᠭᠡᠨ ", //周一
        "index_tuesday": "ᠭᠠᠷᠠᠭ ᠦᠨ ᠬᠣᠶᠠᠷ", //周二
        "index_wednesday": "ᠭᠠᠷᠠᠭ ᠦᠨ ᠭᠤᠷᠪᠠᠨ ", //周三
        "index_thursday": "ᠭᠠᠷᠠᠭ ᠦᠨ ᠲᠦᠷᠪᠡᠨ", //周四
        "index_friday": "ᠭᠠᠷᠠᠭ ᠦᠨ ᠲᠠᠪᠤᠨ", //周五
        "index_saturday": "ᠭᠠᠷᠠᠭ ᠦᠨ ᠵᠢᠷᠭᠤᠭᠠᠨ", //周六
        "index_sunday": "ᠭᠠᠷᠠᠭ ᠦᠨ ᠡᠳᠦᠷ ", //周日
    },
    //资源管理模块
    "resources": {
        ...resources
    },
    "work_panel": { //面板
        ...panel
    },
    "prepare_course": {   //备课
        ...prepare
    },
    "tags": {   //标签
        ...tags
    },
    "single": {   //标签
        ...single
    },
    "teacher": { //教师
        ...teacher
    },
    "notice": { //教师
        ...notice
    }

};
