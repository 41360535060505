var mn = {
    a: '123',
    "public":{
        "username":"平台",
        "password":"123456",
        "copyright":"版权所有",
        "orhon_company":"ᠦᠪᠦᠷ ᠮᠤᠩᠭᠤᠯ ᠤᠨ ᠤᠷᠬᠤᠨ ᠰᠢᠨᠵᠢᠯᠡᠭᠡ ᠮᠡᠷᠬᠡᠵᠢᠯ ᠦᠨ ᠬᠢᠵᠠᠭᠠᠷᠳᠤ ᠺᠤᠮᠫᠠᠨᠢ"

    }

}

module.exports = mn