/*
 * @Author: dembe
 * @Date: 2019-09-15 16:49:03
 * @Last Modified by: 阿拉斯
 * @Last Modified time: 2019-09-21 23:11:28
 */
import gql from 'graphql-tag';

/**
 * 查询地区列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const regionsList = gql`
	query ($page: Int!, $limit: Int!,$condition:RegionCondition) {
        regions (page: $page, limit: $limit,condition:$condition ) {
            code
            msg
            total
            data {
                id
                level
                parent {
                    id
                }
                name {
                    zh
                    mn
                    en
                }
            }
        }
	}
`;
const regionsLists = gql`
	query ($page: Int!, $limit: Int!, $level: Int,$condition:RegionCondition) {
        regions (page: $page, limit: $limit, level: $level,condition:$condition) {
            code
            msg
            total
            data {
                id
                level
                parent {
                    id
                }
                name {
                    zh
                    mn
                    en
                }
            }
        }
	}
`;

/**
 * 查询地区列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const findRegionsData = gql`
	query ($id: Int!) {
        region (id: $id) {
            id
            slug
            order
            level
            parent {
                id
            }
            name {
                zh
                mn
                en
            }
        }
	}
`

/**
 * 删除地区列表数据
 * @param page 分页
 * @param limit 每页数据量
 */
const delRegionsData = gql`
	mutation deleteRegion($id: [Int]!){
		deleteRegion(id: $id){
			code
			msg
		}
	}
`

/**
 * 添加地区数据
 */
const addRegionsData = gql`
	mutation addRegion(
        $id: Int
        $slug: String
        $name: LangInput
        $parent: Int
        $level: Int
        $order: Int
	){
		addRegion(inputData: {
            id: $id
            slug: $slug
            parent: $parent
            level: $level
            order: $order
            name: $name
		}){
			code
			msg
		}
	}
`;

export { regionsList, regionsLists, delRegionsData, addRegionsData, findRegionsData }
