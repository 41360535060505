import { ActivatedRoute, ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from "@angular/router";

export class AppRouteRueseStrategy implements RouteReuseStrategy {
    // static store(route: ActivatedRoute, routedata: any) {

    // }

    // public static handlers:{[key:string]:DetachedRouteHandle} ={};
    // private static waitDelete:string;

    //删除

    //表示对所有路由允许复用 如果你有路由不想利用可以在这加一些业务逻辑判断
    // public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    //     //添加控制器过滤false则不对其缓存，反之。。。
    //     if(!route.data.keep){
    //         return false;
    //     }
    //     if(!route.routeConfig || route.routeConfig.loadChildren) {
    //         return false
    //     }
    //     return true;

    // }
    //当路由离开时会触发，按path作为key存储路由快照&组件当前市里对象
    // public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {

    //     if(AppRouteRueseStrategy.waitDelete && AppRouteRueseStrategy.waitDelete === route.routeConfig.path){
    //         //如果待删除是当前路由则不存储快照
    //         AppRouteRueseStrategy.waitDelete=null;
    //         return;
    //     }
    //     AppRouteRueseStrategy.handlers[route.routeConfig.path] = handle;
    // }
    //若path在缓存中有的都认为允许还原路由
    // public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    //     return !!route.routeConfig && !!AppRouteRueseStrategy.handlers[route.routeConfig.path]
    // }
    //从缓存中获取快照，若无则返回null

    // public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    //     if(!route.routeConfig){
    //         return null
    //     }
    //     return AppRouteRueseStrategy.handlers[route.routeConfig.path]
    // }
    //进入路由触发，判断是否同一路由
    // shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    //     return future.routeConfig === curr.routeConfig
    // }

    public static handlers: { [key: string]: DetachedRouteHandle } = {};
    // static store: any;

    constructor() {
        console.log('constructed');
    }

    /** 删除缓存路由快照的方法 */
    public static deleteRouteSnapshot(path: any): void {

        if (path['shouldReuse']) {
            delete AppRouteRueseStrategy[path['key']];


        }
    }
    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {

        if (!route.data['shouldReuse']) {
            return null;
        }

        return AppRouteRueseStrategy[route.data['key']];
    }
    //当路由离开时会触发，按path作为key存储路由快照&组件当前市里对象
    public store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
        if (route.data['shouldReuse']) {
            AppRouteRueseStrategy[route.data['key']] = handle;
        }
    }

    public shouldDetach(route: any): boolean {
        return !!route.data['shouldReuse'];
    }
    //若path在缓存中有的都认为允许还原路由
    public shouldAttach(route: ActivatedRouteSnapshot): boolean {
        return !!route.data['shouldReuse'] && !!AppRouteRueseStrategy[route.data['key']];
    }

    public shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
        //if(future.)
        if (future['_routerState'].url === '/pages/material-library/home') {
            let curet = current['_routerState'].url.indexOf("/pages/resources/resources-detail");
            if (curet === -1) {
                let data = {
                    shouldReuse: true,
                    key: 'resourceshome'
                }
                this.deleteSnapshot(data)
            }
        }else if(future['_routerState'].url === '/pages/single/my-single') {
            let curet = current['_routerState'].url.indexOf("/pages/single/single-detail");
            if (curet === -1) {
                let data = {
                    shouldReuse: true,
                    key: 'mysingle'
                }
                this.deleteSnapshot(data)
            }
        }else if(future['_routerState'].url === '/pages/single/single-square') {
            let curet = current['_routerState'].url.indexOf("/pages/single/class-detail");
            if (curet === -1) {
                let data = {
                    shouldReuse: true,
                    key: 'singlesquare'
                }
                this.deleteSnapshot(data)
            }
        }else if(future['_routerState'].url === '/pages/single/cloud-square') {
            let curet = current['_routerState'].url.indexOf("/pages/single/cloud-square-detail");
            if (curet === -1) {
                let data = {
                    shouldReuse: true,
                    key: 'cloudsquare'
                }
                this.deleteSnapshot(data)
            }
        }
        else if(future['_routerState'].url === '/pages/single/single-learn') {
            let curet = current['_routerState'].url.indexOf("/pages/single/single-detail");
            if (curet === -1) {
                let data = {
                    shouldReuse: true,
                    key: 'singlelearn'
                }
                this.deleteSnapshot(data)
            }
        }
        else if(future['_routerState'].url === '/pages/single/cloudclass-learn') {
            let curet = current['_routerState'].url.indexOf("/pages/single/cloud-square-detail");
            if (curet === -1) {
                let data = {
                    shouldReuse: true,
                    key: 'cloudclasslearn'
                }
                this.deleteSnapshot(data)
            }
        }else if(future['_routerState'].url === '/pages/single/my-cloudclass') {
            let curet = current['_routerState'].url.indexOf("/pages/single/my-cloud-detail");
            if (curet === -1) {
                let data = {
                    shouldReuse: true,
                    key: 'mycloudclass'
                }
                this.deleteSnapshot(data)
            }
        }
        else if(future['_routerState'].url === '/pages/prepare-course/prepare-square') {
            let curet = current['_routerState'].url.indexOf("/pages/prepare-course/prepare-detail");
            if (curet === -1) {
                let data = {
                    shouldReuse: true,
                    key: 'preparesquare'
                }
                this.deleteSnapshot(data)
            }
        }else if(future['_routerState'].url === '/pages/prepare-course/prepare-design') {
            let curet = current['_routerState'].url.indexOf("/pages/prepare-course/design-detail");
            if (curet === -1) {
                let data = {
                    shouldReuse: true,
                    key: 'preparedesign'
                }
                this.deleteSnapshot(data)
            }
        }else if(future['_routerState'].url === '/pages/prepare-course/my-prepare') {
            let curet = current['_routerState'].url.indexOf("/pages/prepare-course/prepare-detail");
            if (curet === -1) {
                let data = {
                    shouldReuse: true,
                    key: 'myprepare'
                }
                this.deleteSnapshot(data)
            }
        }

        return !!future.data['shouldReuse'];

    }
    deleteSnapshot(data) {
        
        
        AppRouteRueseStrategy.deleteRouteSnapshot(data);
    }
    
}
