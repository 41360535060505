import Config from './index';

export default {
    hostGqlDefault: `${Config.baseUrl}base/graphql`,
    hostGql: [
        {
            url: '/api/region/regiongql',
            name: 'region',
        },
        {
            url: '/api/attendence/graphql',
            name: 'attendance',
        },
        {
            url: '/api/teacher-profile/teachers-profile-gql',
            name: 'teacherprofile',
        },
        {
            url: '/api/material/graphql',
            name: 'material',
        },
        {
            url: '/api/examination/graphql',
            name: 'examination',
        },
        {
            url: '/api/moral/graphql',
            name: 'moral',
        },
        {
            url: '/api/official-document/graphql',
            name: 'officialdocument',
        },
        {
            url: '/api/canteen/graphql',
            name: 'canteen',
        },
        {
            url: '/api/epidemic/graphql',
            name: 'epidemic',
        },
        {
            url: 'api/studentattendence/graphql',
            name: 'studentattendence',
        },
        {
            url: 'api/preparation/graphql',
            name: 'preparation',
        },
        {
            url: 'api/arrangecourse/graphql',
            name: 'arrangecourse',
        }
    ],
};
