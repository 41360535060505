import { Injectable } from '@angular/core';
import lang_trans from '../../../assets/i18n';
// import zh from '../../../assets/i18n/zh.js';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
	providedIn: 'root'
})
export class CustomTranslateService {

	// constructor(private translate: TranslateService) { }
	constructor() { }
	getTranslation(lang: string): Observable<any> {
		// return of(this[this.translate.getDefaultLang()][lang]);

		// return lang=='mn'  of(mnmnmn);
		
		return of(lang_trans[lang]);
	}
}
