import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OrhonUtilService, SettingService } from 'ngx-orhon-mgl-component';
import { BusinessService } from './service/business/business.service';
import { PermissionsService } from './service/permissions/permissions.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    private config: { version: string }
    token: string = ''
    constructor(

        private util: OrhonUtilService,
        public translate: TranslateService,
        private settings: SettingService,
        private httpClient: HttpClient,
        private business: BusinessService,
        private route: ActivatedRoute,
        private permissions: PermissionsService
    ) {
        this.route.queryParams.subscribe(res => {
            if (res.token) {
                this.token = String(res.token);
                this.business.setItem('token', this.token);
                this.permissions.getUserList()
            }

        });

    }

    ngOnInit(): void {
        this.translate.setDefaultLang('mn');
        this.translate.use('mn');
        this.config = require('./../assets/config.json');
        const headers = new HttpHeaders()
            .set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache');

        this.httpClient
            .get<{ version: string }>('/assets/config.json', { headers })
            .subscribe(config => {
                if (config.version !== this.config.version) {
                    this.httpClient
                        .get('', { headers, responseType: 'text' })
                        .subscribe(() => location.reload());
                }
            });
        this.business.getcolors().subscribe(async item => {

            let colors = this.business.getItem('userInfo');
            const backgroundColors = colors.school ? (colors.school.settings ? colors.school.settings.themeSettings.zh : '#206F53') : '';
            const color1 = await this.util.color(backgroundColors, 0.05, true);
            const color2 = await this.util.color(backgroundColors, 0.8, true);
            const color3 = await this.util.color(backgroundColors, 0.38, true);
            const theme = {
                '--omc-color-primary': backgroundColors,
                '--omc-color-primary-rgb': color1,
                '--omc-color-primary-contrast': '#ffffff',
                '--omc-color-primary-contrast-rgb': color2,
                '--omc-color-primary-background-rgb': color3
            };
            this.settings.setActiveTheme(theme);

        })
    }
}
