import gql from 'graphql-tag';

const gradesNewList = gql`
	query ($page: Int!, $limit: Int!,$condition:GradesInput){
		gradesNew (page: $page, limit: $limit,condition:$condition){
            code
            msg
            total
            data {
                id
                name {
                    en
                    mn
                    zh
				}
				periods{
					name{
					  en
				    	mn
						zh
					}
				}
            }
		}
	}
`
export {
    gradesNewList
}