import gql from 'graphql-tag';
const dictionaysList = gql`
	query ($dictionary_code: String) {
		Dictionarys (page: 1, limit: 1000, condition: {
            dictionary_code: $dictionary_code
        }) {
            code
            msg
            data {
                id
                name {
                    en
                    mn
                    zh
                }
                dictionary_value
            }
		}
	}
`;
export {
    dictionaysList
}